import {Box, Flex} from "@chakra-ui/react";
import {Body1, Subhead2} from "./index";
import {s} from "../helper/common";


export const LabelItem = (props) => {
  const {
    title,
    description,
    textAlign = 'left',
    w,
    extraMainLabel
  } = props

  return (
    <Flex
      direction={'column'}
      gap={s(['8px', '12px', '12px', '16px', '24px'])}
      textAlign={textAlign}
      w={w}
    >
      {title ? <Subhead2 {...extraMainLabel}>{title}</Subhead2> : null}
      {description ? <Body1>{description}</Body1> : null}
    </Flex>
  )
}
