import {animated, useTransition} from "@react-spring/web";
import {Headline1, RSymbol} from "../../../../../components";
import React, {useEffect, useState} from "react";


const textArray = [
  <>introducing <br/> cryptofood<RSymbol/></>,
  <>a game-changing <br/> food ecosystem</>
];
export const Title = ({}) => {

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIndex((prevIndex) => prevIndex + 1);
    }, 2000);

    return () => clearInterval(timer);
  }, []);

  const transition = useTransition(textArray[index], {
    key: textArray[index],
    from: {opacity: 0, position: 'absolute'},
    enter: {opacity: 1, position: 'static'},
    leave: {opacity: 0, position: 'absolute'},
    config: {duration: 500},
  });

  return (
    transition((style, item) => (
      <animated.div style={style}>
        <Headline1>{item}</Headline1>
      </animated.div>
    ))
  )
}