import React from "react";
import {componentsSizeTheme} from "../config/theme/sizes";
import {Button as ChakraButton} from "@chakra-ui/react";

export const Button = (props) => (
  <ChakraButton
    {...props}
    {...componentsSizeTheme.Button}
  />
)

export const GradientButton = (props) => (
  <ChakraButton
    {...componentsSizeTheme.Button}
    variant='outline'
    {...props}
    border={"2px solid transparent"}
    bg={` 
      linear-gradient(90deg, ${props.bgColor}, ${props.bgColor}) padding-box, 
      linear-gradient(90deg, #009A6C 38.92%, #00477A 95.1%) border-box
    `}
    _hover={{
      border: "2px solid transparent",
      color: props.color,
    }}
    _active={{
      border: "2px solid transparent",
      color: props.color,
    }}
  />
)

export const LinkButtonSmall = (props) => (
  <ChakraButton
    {...componentsSizeTheme.Body2}
    variant={'link'}
    as={'a'}
    target={"_blank"}
    {...props}
  />
)


export const LinkButtonLarge = (props) => (
  <ChakraButton
    {...componentsSizeTheme.Subhead1}
    variant={'link'}
    as={'a'}
    target={"_blank"}
    {...props}
  />
)
