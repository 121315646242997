import {lunchBoxer2, snackSprinterImg2} from "../../../assets/images";
import {Image} from "@chakra-ui/react";
import {s} from "../../../../helper/common";
import {imageTransTime} from "../../../../config/theme/consts";

const transition = `width ${imageTransTime}, height ${imageTransTime}`

export const deviceSectionStartAnchorId = 'device-section'

export const dataInfo = {
  lunch: {
    img: (
      <Image
        objectFit={'contain'}
        src={lunchBoxer2}
        w={s(["152px", "320px", "374px", "504px", "600px"])}
        h={s(["186px", "392px", "458px", "614px", "734px"])}
        transition={transition}
      />
    ),
    title: 'lunchboxer',
    description: 'Discover our revolutionary Bottle-size heating device, the first of its kind in the shape of a flask. With a size slightly larger than a 200ml snack pack, it is designed for convenience and portability. Simply insert the food package into the device, and it will heat up to your desired temperature. Within just 4-5 minutes, you can enjoy a fully warmed meal on the spot.',
    deviceInfo: {
      btn: {
        pos: {
          top: s(["50px", "90px", "110px", "140px", "180px"]),
          right: s(["-80px", "-160px", "-180px", "-170px", "-170px"]),
        },
        lineW: s(["152px", "308px", "358px", "402px", "454px"]),
        text: <>
          {"One button does it all —  "}<br/>
          {"enable and disable heating by "}<br/>
          {"pressing it"}
        </>
      },
      port: {
        pos: {
          bottom: s(["25px", "75px", "95px", "125px", "170px"]),
          left: s(["-75px", "-75px", "-120px", "-100px", "-160px"]),
        },
        lineW: s(["115px", "156px", "214px", "250px", "346px"]),
        text: <>
          {'Standard USB-C port'}<br/>
          {'for charging'}
        </>
      },
      bottom: {
        pos: {
          bottom: s(["-15px", "20px", "10px", "40px", "10px"]),
          right: s(["-80px", "-160px", "-180px", "-170px", "-170px"]),
        },
        maxWidth: s(["130px", "auto"]),
        lineW: s(["104px", "202px", "246px", "250px", "274px"]),
        text: "Sealed lid prevents spillage"
      }
    },

    steps: {}
  },
  snack: {
    img: (
      <Image
        objectFit={'contain'}
        src={snackSprinterImg2}
        w={s(["248px", "494px", "502px", "822px", "822px"])}
        h={s(["178px", "358px", "614px", "646px", "646px"])}
        transition={transition}
        m={s(["0", "0", "-120px 0 -40px", '0'])}
      />
    ),
    title: 'snacksprinter',
    description: 'Introducing our ultra-compact Pocket-size heating device, designed to keep you fueled even on the move. With a size comparable to a smartphone, it offers ultimate portability. The device features a pass-through channel that heats the food inside a small tube-like adapter attached to the packet\'s nozzle. No need to wait for the entire packet to heat up - the food warms up rapidly, portion by portion, within just 5-10 seconds. Experience the convenience of quickly and efficiently warming your meals wherever your adventures take you. Stay energized and enjoy piping hot food on the go with our Pocket-size heating device.',
    deviceInfo: {
      btn: {
        pos: {
          top: s(["60px", "120px", "105px", "160px", "230px"]),
          right: s(["-80px", "-160px", "-190px", "-170px", "-170px"]),
        },
        lineW: s(["152px", "308px", "358px", "402px", "454px"]),
        text: <>
          {"One button does it all —  "}<br/>
          {"enable and disable heating by "}<br/>
          {"pressing it"}
        </>
      },
      port: {
        pos: {
          bottom: s(["20px", "70px", "140px", "100px", "170px"]),
          left: s(["-75px", "-75px", "-120px", "-100px", "-160px"]),
        },
        lineW: s(["115px", "156px", "214px", "250px", "346px"]),
        text: <>
          {'Standard USB-C port'}<br/>
          {'for charging'}
        </>
      },
      bottom: {
        pos: {
          bottom: s(["-5px", "10px", "60px", "0px", "60px"]),
          right: s(["-80px", "-160px", "-190px", "-170px", "-170px"]),
        },
        lineW: s(["104px", "223px", "274px", "250px", "274px"]),
        text: <>
          {'Plug’n’Eat connector '}<br/>
          {'for the foodbag'}
        </>
      }
    },

    steps: {}
  }
}