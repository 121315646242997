import { Box, VStack } from "@chakra-ui/react";
import { DualBlock, Slider, Subhead1 } from "../../../../../../components";
import { s } from "../../../../../../helper/common";
import { UseCaseCard } from "../../../../../../components/cards";
import React from "react";
import { sliderOptions } from "./sliderHelper";
import { sliderData } from "./data";
import {spacings} from "../../../../../../config/theme/sizes";


export const SliderBlock = () => {

  const options = {
    ...sliderOptions,
    type: 'loop',
    easing: 'ease',
    trimSpace: 'move'
  }

  return (
    <Box
      pt={spacings.section.mainVertical}
      pb={spacings.section.mainVertical}
      paddingInline={spacings.section.paddingInlineOverflow}
    >
      <VStack
        gap={s(["24px", "32px", "32px", "48px", "56px"])}
      >
        <DualBlock
          templateColumns={s(['1fr', '276px 1fr', '310px 1fr', '439px 1fr', '546px 1fr'])}
          left={<Box />}
          right={
            <Subhead1
            // w={s(["100%", "552px", "496px", "530px", "740px"])}
            >
              {"cryptofood® is for you,"} <br /> {"if you are..."}
            </Subhead1>
          }
        />

        <Slider
          options={options}
          items={sliderData}
          CardComponent={({ isActive, card, onClick }) => (
            <UseCaseCard
              isActive={isActive}
              key={card.id}
              imgSrc={card.img}
              label={card.label}
              description={card.description}
              onClick={onClick}
            />
          )
          }
        />
      </VStack>
    </Box>
  )
}