import {Grid} from "@chakra-ui/react";
import {s} from "../helper/common";


export const DualBlock = (props) => {
  const {
    left,
    right,
    p,

    templateColumns = s(['1fr', '276px 1fr', '434px 1fr', '616px 1fr', '780px 1fr']),
    templateRows = s(['1fr']),
    gap = s(['12px 0', '16px 0', '88px 0']),
    width = '100%',
    align = "start",
    ...restProps
  } = props

  return (
    <Grid
      p={p}
      templateColumns={templateColumns}
      templateRows={templateRows}
      gap={gap}
      width={width}
      align={align}
      {...restProps}
    >
      {left}
      {right}
    </Grid>
  )
}
