import {theme as chakraTheme} from "@chakra-ui/react";
import {accentColorSchema, themeColors} from "../colors";


const {Button} = chakraTheme.components

export const buttonTheme = () => ({
  ...Button,
  defaultProps: {
    ...Button.defaultProps,
    colorScheme: accentColorSchema,
  },
  baseStyle: {
    ...Button.baseStyle,
    fontWeight: '375',
    _hover: {
      _disabled: {
        ...Button.baseStyle._hover._disabled,
        color: 'grey.400',
        borderColor: 'grey.300',
      }
    },
    _disabled: {
      ...Button.baseStyle._disabled,
      color: 'grey.400',
      borderColor: 'grey.300',
      opacity: .6,
    }
  },
  variants: {
    ...Button.variants,
    outline: {
      ...Button.variants.outline,
      color: 'green.300',
      bg: 'transparent',
      borderRadius: '100px',

      _hover: {
        ...Button.variants.outline._hover,
        color: 'green.400',
        borderColor: 'green.300',
        bg: 'transparent',
      },
      _active: {
        ...Button.variants.outline._active,
        bg: 'transparent',
      },
    },

    link: {
      ...Button.variants.link,
      fontWeight: '375',
      color: themeColors.green["80%"],

      _hover: {
        ...Button.variants.link._hover,
        textDecoration: 'none',
        color: 'green.400',
      },
      _active: {
        color: 'green.400',
      },
      _disabled: {
        color: 'grey.400',
      }
    }
  }
})