import {Box, Flex, useBreakpointValue} from '@chakra-ui/react'
import {LunchBoxDescription, NutritionDescription, SnackDescription, TokenDescription} from './descriptions'
import {LunchBoxerImg, NutritionImg, SnackSprinterImg, TokenImg, WrapperImages} from './components/ImageBox'
import {s} from '../../../../helper/common'
import {animated} from '@react-spring/web'
import React from "react";
import {viewSizes} from "../../../../config/theme/sizes";


export const EcosystemSection = (props) => {
  const {
    sectionBlur,
    sectionOpacity,
    descriptionsOpacity,
    lunchBottom,
    snackTop,
    nutritionBottom,
    tokenTop,
    refHeaderWrapper
  } = props
  const ImgsBlockV2 = (props) => (
    <WrapperImages {...props}>
      <SnackSprinterImg
        snackTop={snackTop}
      />
      <LunchBoxerImg
        lunchBottom={lunchBottom}
      />
      <NutritionImg
        nutritionBottom={nutritionBottom}
      />
      <TokenImg
        tokenTop={tokenTop}
        right={s(['16%', '18%', '18%', '15%', '18%'])}
      />
    </WrapperImages>
  )

  function updateScale() {
    const currentHeight = window.innerHeight;
    const currentWidth = window.innerWidth;
    const deskSize = currentWidth > viewSizes.desktop && currentWidth < viewSizes.wide
    // const mobileSize = currentWidth > viewSizes.base && currentWidth < viewSizes.tablet
    const needHeight = 800

    if (deskSize) {
      const scale = currentHeight / needHeight
      return scale > 1 ? 1 : scale
    }
    return 1
  }

  const scale = updateScale()

  const headerHeight = refHeaderWrapper?.current?.clientHeight

  const animatedHeight = useBreakpointValue(s(['100vh', 'auto', 'auto']))
  const animatedPosition = useBreakpointValue(s(['absolute', 'static', 'static']))
  const animatedTop = useBreakpointValue(s([`-${headerHeight}px`, `0`, '0']))

  return (
    <Box
      position={'sticky'}
      top={s([`${headerHeight}px`, `15vh`, '12vw', `${150 * (scale ** 5)}px`, '12vw'])}
      height={s(['100vh', 'auto'])}
      display={s(["flex"])}
      alignItems={s(["center", "center", "flex-start"])}
      transform={`scale(${scale})`}
      zIndex={1}
    >
      <animated.div style={{
        width: '100%',

        height: animatedHeight,
        position: animatedPosition,
        top: animatedTop,
        zIndex: 1,

        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',

        filter: sectionBlur.to(value => `blur(${value}px)`),
        WebkitFilter: sectionBlur.to(value => `blur(${value}px)`),
        opacity: sectionOpacity
      }}>
        <Flex
          position={'relative'}
          justify={"center"}
          p={s(["0 8px", "0 16px", "0 16px", "0 16px", "0 24px"])}
        >
          <Flex justify={"center"}>
            <ImgsBlockV2/>
          </Flex>
          <Box
            position={'absolute'}
            margin={'auto'}
            left={0}
            right={0}
            top={0}
            bottom={0}
            w={s(["300px", "700px", "750px", "1000px", "1200px"])}
            h={s(["570px", "920px", "1000px", "1220px", "1580px"])}
            zIndex={0}
          >
            <LunchBoxDescription
              position={'absolute'}
              left={s(["0", "-17px", "-72px", "-130px", '-250px'])}
              top={s(["70px", "160px", "170px", "200px", '250px'])}
              descriptionsOpacity={descriptionsOpacity}
            />
            <SnackDescription
              wrapperDirection={s(["column-reverse"])}
              position={'absolute'}
              left={s(["10px", "-17px", "-120px", "-205px", '-335px'])}
              top={s(["360px", "660px", "715px", "740px", '1070px'])}
              descriptionsOpacity={descriptionsOpacity}
            />
            <NutritionDescription
              position={'absolute'}
              right={s(["0px", "-17px", "-120px", "-205px", '-335px'])}
              top={s(["80px", "200px", "300px", "360px", '450px'])}
              descriptionsOpacity={descriptionsOpacity}
            />
            <TokenDescription
              wrapperDirection={s(["column-reverse"])}
              position={'absolute'}
              right={s(["-10px", "-17px", "-120px", "-205px", '-335px'])}
              top={s(["380px", "650px", "700px", "740px", '1070px'])}
              descriptionsOpacity={descriptionsOpacity}
            />
          </Box>
        </Flex>
      </animated.div>
    </Box>
  )
}