import {Icon} from "@chakra-ui/react";

export const ArrowRightIcon = (props) => (
  <Icon
    {...props}
    viewBox="0 0 56 56"
    color={"currentColor"}
  >
    <path
      d="M34.876 47.44L31.708 44.56L45.172 30.16H3.12402V25.912H45.172L31.708 11.512L34.876 8.56L52.876 28L34.876 47.44Z"
      fill="currentColor"/>
  </Icon>
)
export const ArrowLeftIcon = (props) => (
  <ArrowRightIcon
    {...props}
    transform="rotate(180deg)"
  />
)


export const SliderArrowLeftIcon = (props) => (
  <Icon
    {...props}
    viewBox="0 0 56 56"
    color={"currentColor"}
  >
    <path d="M45.4974 28.0003H8.16406M8.16406 28.0003L22.7474 13.417M8.16406 28.0003L22.1641 42.5837"
          fill="currentColor" stroke="currentColor"  strokeWidth="2"/>
  </Icon>
)


export const SliderArrowRightIcon = (props) => (
  <SliderArrowLeftIcon
    {...props}
    transform="rotate(180deg)"
  />
)


export const GreenSliderArrowLeftIcon = (props) => (
  <Icon
    {...props}
    viewBox="0 0 134 51"
    color={"currentColor"}
  >
    <path d="M134 25.3701H2M2 25.3701L25.925 1.37012M2 25.3701L25.925 49.3701" stroke="#009A6C" strokeWidth="2"/>
  </Icon>
)
export const GreenSliderArrowRightIcon = (props) => (
  <GreenSliderArrowLeftIcon
    {...props}
    transform="rotate(180deg)"
  />
)