import newsImg1 from "../../../../assets/images/news/newsImg1.png";
import newsImg2 from "../../../../assets/images/news/newsImg2.png";
import newsImg3 from "../../../../assets/images/news/newsImg3.png";
import newsImg4 from "../../../../assets/images/news/newsImg4.png";
import newsImg5 from "../../../../assets/images/news/newsImg5.png";


export const itemsData = [
  {
    id: 5,
    img: newsImg5,
    date: "30 MAY 2024",
    description: `
      <b>The first promotion of the Cryptofood loyalty program</b><br/> 
      Each CFD token holder can claim a set of three different ready-made food packages. To do this, just send a message "I want Cryptofood" in Blockscan Chat messenger. Click here to read more 
    `,
    link: "https://t.me/cryptofoodnews/38",
  },
  {
    id: 1,
    img: newsImg1,
    date: "15 MAR 2024",
    description: `
      <b>Today our CFD token was launched!</b><br/> 
      The code is verified and published. 100% of the tokens in the liquidity pool. Liquidity is blocked for 100 years. The creators of the project did not keep any tokens. We bought out some of the tokens from the pool and distributed them among the various teams of the project as a thank you for the cooperation. 
    `,
    link: "https://t.me/cryptofoodnews/31",
  },
  {
    id: 2,
    img: newsImg2,
    date: "9 JAN 2024",
    description: "The development team is working hard on completely new devices",
    link: "https://t.me/cryptofoodnews/28",
  },
  {
    id: 3,
    img: newsImg3,
    date: "14 NOV 2023",
    description: "Cryptofood Project has Entered Active Development Phase! Our engineers are hard at work developing prototypes as we enter an exciting phase of the Cryptofood project.",
    link: "https://t.me/cryptofoodnews/20",
  },
  {
    id: 4,
    img: newsImg4,
    date: "29 OCT 2023",
    description: "The Cryptofood project received a trademark registration certificate",
    link: "https://t.me/cryptofoodnews/17",
  },
]