import {s} from "../../helper/common";

export const viewSizes = {
  base: 0,
  small: 320,
  mobile: 768,
  tablet: 1024,
  desktop: 1440,
  wide: 1920,
}

export const componentsSizeTheme = {
  Headline1: {
    as: 'h1',
    color: "green.900",
    fontSize: s(['40px', '80px', '80px', '96px', '112px']),
    lineHeight: s(['44px', '84px', '84px', '100px', '116px']),
    fontWeight: ['375'],
  },
  Headline2: {
    as: 'h2',
    color: "green.900",
    fontSize: s(['24px', '56px', '56px', '72px', '88px']),
    lineHeight: s(['32px', '64px', '64px', '80px', '96px']),
    fontWeight: ['375'],
  },
  Subhead1: {
    as: 'h3',
    color: "green.900",
    fontSize: s(['18px', '32px', '32px', '48px', '56px']),
    lineHeight: s(['22px', '40px', '40px', '56px', '64px']),
    fontWeight: ['375'],
  },
  Subhead2: {
    as: 'h4',
    color: "green.900",
    fontSize: s(['16px', '24px', '24px', '32px', '40px']),
    lineHeight: s(['22px', '32px', '32px', '40px', '48px']),
    fontWeight: ['375'],
  },
  Caption1: {
    as: 'h5',
    color: "green.900",
    fontSize: s(['14px', '16px', '16px', '24px', '32px']),
    lineHeight: s(['18px', '20px', '20px', '28px', '34px']),
    fontWeight: ['375'],
  },
  Body1: {
    as: 'p',
    color: "green.900",
    fontSize: s(['12px', '14px', '14px', '16px', '18px']),
    lineHeight: s(['16px', '18px', '18px', '20px', '22px']),
    fontWeight: ['375'],
  },
  Body2: {
    as: 'p',
    color: "green.900",
    fontSize: s(['12px', '14px', '14px', '16px', '18px']),
    lineHeight: s(['16px', '18px', '18px', '20px', '22px']),
    fontWeight: ['375'],
  },
  Label1: {
    as: 'span',
    color: "green.900",
    fontSize: s(['12px', '14px', '14px', '16px', '18px']),
    lineHeight: s(['12px', '14px', '14px', '16px', '18px']),
    fontWeight: ['375'],
  },
  Button: {
    fontSize: s(['16px', '24px', '24px', '32px', '40px']),
    lineHeight: s(['22px', '32px']),
    padding: s(['1px 12px', '4px 24px', '4px 32px', '12px 32px','12px 32px']),
    fontWeight: ['375'],
    height: 'auto'
  },
  Logo: {
    boxSize: s(['32px','40px','48px','56px','56px'])
  }
}

export const spacings = {
  section: {
    main: s(['56px 8px', '120px 16px', '120px 16px', '160px 16px', '176px 24px']),
    mainVertical: s(['56px', '120px', '120px', '160px', '176px']),
    second: s(['48px 8px', '96px 16px', '96px 16px', '120px 16px', '136px 24px']),
    // для выхода за границы
    marginInlineOverflow: s(['-8px', '-16px', '-16px', '-16px', '-24px']),
    paddingInlineOverflow: s(['8px', '16px', '16px', '16px', '24px'])
  },
  scrollSlider: {
    news: {
      p: s(['0 8px 20px', '0 16px 20px', '0 16px 20px', '0 16px 20px', '0 24px 20px']),
      m: s(['0 -8px', '0 -16px', '0 -16px', '0 -16px', '0 -24px'])
    },
  },
}