import { Box, Flex, HStack, Image, VStack } from "@chakra-ui/react";
import { Body1, Body2, Label1, Subhead1, Subhead2 } from "./text";
import { s } from "../helper/common";
import { Button } from "./buttons";
import { themeColors } from "../config/theme/colors";
import { transTime } from "../config/theme/consts";
import { useEffect, useState } from "react";

export const CardAdvantage = (props) => {
  const {
    IconComponent,
    title,
    description,
    bgColor,
    textColor,
    rotation,
    ...restPops
  } = props

  const iconSize = s(['32px', '56px', '56px', '72px', '80px'])
  return (
    <Box
      p={s(['16px', '16px', '24px', '32px', '40px'])}
      bg={bgColor}
      borderRadius={s(['16px', '16px', '24px', '32px', '32px'])}
      color={textColor}
      w={s(['270px', '350px', '432px', '614px', '692px'])}
      transform={rotation.map(r => `rotate(${r})`)}
      {...restPops}
    >
      <VStack align={'left'}>
        <HStack gap={s(['8px', '16px', '24px', '40px', '40px'])}>
          <IconComponent
            w={iconSize}
            h={iconSize}
          />
          <Subhead2 color={'currentColor'} textAlign={'left'}>{title}</Subhead2>
        </HStack>
        <Body1 color={'currentColor'} textAlign={'left'}>
          {description}
        </Body1>
      </VStack>
    </Box>
  )
}

export const DevicesCard = (props) => {
  const {
    title,
    description,
    onClick,
    ...restProps
  } = props

  return (
    <Box
      bg={'whiteAlpha.400'}
      p={s(['16px', '16px', '16px', '24px 16px', '32px 24px'])}
      borderRadius={s(['16px', '16px', '24px', '40px', '40px'])}
      w={s(['228px', '276px', '310px', '440px', '504px'])}
      backdropFilter={"blur(22px)"}
      _hover={{
        bg: 'whiteAlpha.800',
        transition: 'background 0.3s ease',
      }}
      {...restProps}
    >
      <VStack
        gap={s(["16px", "24px", "32px", '40px'])}
        align="left"
      >
        <VStack gap={'8px'} align="left">
          <Subhead1 color={'green.300'}>{title}</Subhead1>
          <Body1>{description}</Body1>
        </VStack>
        <Box>
          <Button variant='outline' onClick={onClick}>learn more</Button>
        </Box>
      </VStack>
    </Box>
  )
}


export const DeviceContentCard = (props) => {
  const {
    isActive = true,
    imgSrc,
    num,
    title,
    description,
    onClick
  } = props

  const imageSize = isActive
    ? s(['88px', '224px', '224px', '264px', '312px'])
    : s(['64px', '138px', '138px', '156px', '196px'])

  const imageBorderRadius = isActive
    ? s(['16px', '16px', '24px'])
    : s(['8px', '8px', '16px'])

  const textBlockPadding = isActive
    ? []
    : s(['4px', '4px', '12px', '16px', '24px'])

  return (
    <Box
      color={'green.900'}
      w={["100%"]}
      cursor="pointer"
      opacity={isActive ? 1 : 0.5}
      onClick={onClick}
      transition={`opacity ${transTime} ease-in-out`}
      style={{
        "-webkit-tap-highlight-color": "rgba(255, 255, 255, 0)"
      }}
    >
      <HStack
        gap={s(['8px', '8px', '24px', '40px', '40px'])}
        align={'start'}
      >
        <Image
          src={imgSrc}
          alt='news'
          borderRadius={imageBorderRadius}
          w={imageSize}
          h={imageSize}
          transition={`
            width ${transTime} ease-in-out, 
            height ${transTime} ease-in-out,
            border-radius ${transTime} ease-in-out
          `}
          objectFit={'cover'}
        />
        <VStack
          gap={s(['8px', '8px', '12px', '16px', '24px'])}
          align={'start'}
          w={isActive
            ? s(['100%', '100%', '100%', "400px", "514px"])
            : s(['100%', '100%', '100%', "400px", "540px"])
          }
        >
          <Flex
            pt={textBlockPadding}
            direction={'column'}
            align={'start'}
            transition={`padding-top ${transTime} ease-in-out`}
          >
            <Label1 color={themeColors.green["60%"]}>{num}</Label1>
            <Subhead2>{title}</Subhead2>
          </Flex>
          <Body1 color={'green.700'}>{description}</Body1>
        </VStack>
      </HStack>
    </Box>
  )
}


export const RoadmapCard = (props) => {
  const {
    title,
    label,
    description,
  } = props

  return (
    <Box
      bg={'green.100'}
      p={s(['12px', '16px', '16px', '24px', '32px'])}
      borderRadius={s(['8px', '16px'])}
      w={s(['272px', '400px', '440px', '546px', '646px'])}
    >
      <VStack
        gap={s(['12px', '16px', '16px', '24px', '32px'])}
        align="left"
      >
        <VStack gap={'4px'} align="left">
          <Body2 textTransform={"uppercase"}>{label}</Body2>
          <Subhead1 color={'green.400'}>{title}</Subhead1>
        </VStack>
        <Body1>{description}</Body1>
      </VStack>
    </Box>
  )
}

export const FeatureCard = (props) => {
  const {
    isActive = false,
    title,
    description,
    onClick
  } = props

  return (
    <Box
      p={s(['16px', '24px', '24px', '32px', '40px'])}
      bg={themeColors.grey["60%"]}
      borderRadius={s(['16px', '24px', '24px', '32px', '32px'])}
      color={'white'}
      w={s(['280px', '260px', '294px', '416px', '522px'])}
      h={s(['114px', '218px', '200px', '224px', '274px'])}
      onClick={onClick}
      opacity={isActive ? 1 : 0.5}
      flexShrink={0}
      transition={`opacity 0.2s`}
    >
      <VStack
        gap={s(['12px', '12px', '16px', '20px', '32px'])}
        align="left"
      >
        <Subhead2 color={'currentColor'}>{title}</Subhead2>
        <Body1 color={'currentColor'}>{description}</Body1>
      </VStack>
    </Box>
  )
}

export const NewsCard = (props) => {
  const {
    id,
    imgSrc,
    date,
    description,
    direction,
    ...restProps
  } = props


  const imageSize = s(['280px', '230px', '248px', '352px', '390px'])
  return (
    <Box
      id={id}
      borderRadius={s(['16px', '16px', '24px', '32px', '32px'])}
      color={'green.900'}
      w={imageSize}
      role="group"
      cursor="pointer"
      flexShrink={0}
      {...restProps}
    >
      <VStack
        gap={s(['12px', '12px', '12px', '16px', '24px'])}
        align="left"
      >
        <Image
          src={imgSrc}
          alt='news'
          borderRadius={'16px'}
          w={imageSize}
          h={imageSize}
          objectFit={'cover'}
          _groupHover={{
            boxShadow: '0px 8px 15px 8px #00000017;'
          }}
          transition={`box-shadow ${transTime}`}
        />
        <Body2 direction={direction}>{date}</Body2>
        <Body1
          direction={direction}
          dangerouslySetInnerHTML={{__html: description}}
        />
      </VStack>
    </Box>
  )
}

export const UseCaseCard = (props) => {
  const {
    isActive = false,
    imgSrc,
    label,
    description,
    onClick,
  } = props

  // const imageWidth = isActive
  //   ? s(['280px', '334px', '370px', '530px', '602px'])
  //   : s(['248px', '252px', '286px', '400px', '514px'])

  // const imageHeight = isActive
  //   ? s(['160px', '188px', '208px', '298px', '338px'])
  //   : s(['132px', '140px', '160px', '225px', '274px'])

  // const boxPadding = isActive
  //   ? s(['0px'])
  //   : s(['14px 0', '24px 0', '24px 0', '36px 0', '32px 0'])

  // const boxShadow = isActive
  //   ? '0px 8px 15px 8px #00000017;'
  //   : 'none'

  const imageWidth = s(['280px', '334px', '370px', '530px', '602px'])

  const imageHeight = s(['160px', '188px', '208px', '298px', '338px'])

  const boxPadding = s(['0px'])

  const boxShadow = isActive
    ? '0px 8px 15px 8px #00000017;'
    : 'none'


  const boxOpacity = isActive ? '1' : '0.5'

  return (
    <Box
      color={'green.900'}
      w={imageWidth}
      p={boxPadding}
      opacity={boxOpacity}
      onClick={onClick}
      // flexShrink={0}
      cursor={'pointer'}
      transition={'all 0.2s ease'}
      transformOrigin={'center'}
    >
      <VStack
        gap={s(['16px', '24px', '24px', '32px', '40px'])}
        align="left"
        position={'relative'}
      >
        <Box
          position={'absolute'}
          top={['8px', '16px', '16px', '16px', '24px']}
          left={['8px', '16px', '16px', '16px', '24px']}
          p={['4px 12px', ' 4px 16px', ' 4px 16px', ' 8px 16px', ' 8px 16px']}
          borderRadius={'100px'}
          bg={'white'}
        >
          <Subhead2>{label}</Subhead2>
        </Box>
        <Image
          src={imgSrc}
          alt='news'
          borderRadius={'8px'}
          w={imageWidth}
          h={imageHeight}
          objectFit={'cover'}
          boxShadow={boxShadow}
          transition={'all 0.2s ease'}
        />
        <Body1>{description}</Body1>
      </VStack>
    </Box>
  )
}