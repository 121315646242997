import {stepCards} from "../utils";
import {Box} from "@chakra-ui/react";
import React from "react";
import {animated} from '@react-spring/web'

export const StepCardBlock = (props) => {
  const {
    stepNum,
    opacity = 0
  } = props
  const stepCard = stepCards[stepNum]

  return (
    <animated.div style={{
      opacity: opacity,
      transition: 'opacity .2s',
      width: 'fit-content',
      position: 'absolute',
      bottom: 0,
      ...stepCard.pos
    }}>
        {stepCard?.img}
        <Box position={'relative'}>{stepCard?.card}</Box>
    </animated.div>
  )
}