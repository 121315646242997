import {ChakraProvider} from '@chakra-ui/react'
import Landing from "./Landing/Landing";
import {customTheme} from "./config/theme/theme";


const App = () => (
  <ChakraProvider
    theme={customTheme}
    children={<Landing />}
  />
)
export default App