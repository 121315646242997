import {Box, Fade, Flex} from "@chakra-ui/react";
import React from "react";
import {Tab, TabList, Tabs} from "../../../../../components";
import {s} from "../../../../../helper/common";
import {devicesEnum} from "../enum";


export const DevicesHeader = (props) => {
  const {
    isOpen,
    selectedDeviceKey,
    setSelectedDeviceKey
  } = props

  const tabs = [
    {id: devicesEnum.snack, title: "snacksprinter"},
    {id: devicesEnum.lunch, title: "lunchboxer"},
  ]

  const selectedIndex = tabs.findIndex(tab => tab.id === selectedDeviceKey)
  return (
    <Box
      top={"-1px"}
      zIndex={100}
      position={'sticky'}
    >
      <Fade
        in={isOpen}
        delay={{enter: .1, exit: 0}}
      >
        <Flex
          w={'100%'}
          justify="center"
          align="center"
          bg={'white'}
        >
          <Tabs
            index={selectedIndex}
            onChange={(selectedIndex) => setSelectedDeviceKey(tabs[selectedIndex].id)}
          >
            <TabList h={s(["32px", "32px", "42px", "44px", "46px"])}>
              {tabs.map(tab => (
                <Tab key={tab.id}>{tab.title}</Tab>
              ))}
            </TabList>
          </Tabs>
        </Flex>
      </Fade>
    </Box>
  )
}