import {Icon} from "@chakra-ui/react";

export const EcoIcon = (props) => (
  <Icon
    color={"currentColor"}
    {...props}
    viewBox="0 0 80 80"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M61.0856 8.56977L60.5914 7.86572L60.153 8.60574C58.3049 11.7246 54.9704 14.4238 50.9148 17.1018C48.9301 18.4123 46.7913 19.7064 44.591 21.0375L44.4692 21.1112C42.2297 22.4662 39.9304 23.8595 37.6896 25.3369C28.7457 31.2341 20.5555 38.585 20.5555 50.6537C20.5555 56.9628 22.6704 61.691 26.4324 64.8302C26.5278 64.9098 26.6242 64.9884 26.7216 65.0658C21.9717 68.135 16.6805 70.7924 10.9272 72.8091L11.2948 73.8577C17.2762 71.761 22.7655 68.9822 27.6768 65.7701C31.3151 68.2552 36.149 69.4445 41.7557 69.4445C47.2879 69.4445 54.2083 65.4935 59.7111 59.5609C65.2307 53.61 69.4444 45.5402 69.4444 37.1242C69.4444 23.8112 63.8897 12.5647 61.0856 8.56977ZM28.6807 65.1022C32.0377 67.2559 36.4898 68.3334 41.7557 68.3334C46.8409 68.3334 53.487 64.6373 58.8964 58.8053C64.289 52.9914 68.3332 45.1788 68.3332 37.1242C68.3332 24.8 63.4597 14.2728 60.6444 9.90567C58.5999 12.9388 55.2997 15.5379 51.5271 18.029C49.5239 19.3518 47.3688 20.6556 45.1756 21.9825L45.0444 22.0619C42.8025 23.4183 40.5212 24.8008 38.3013 26.2645C29.4023 32.1321 21.6666 39.1929 21.6666 50.6537C21.6666 56.6974 23.6806 61.0869 27.1443 63.9771C27.3263 64.129 27.5125 64.2769 27.7029 64.4208C32.1627 61.439 36.1247 58.097 39.5199 54.5936C46.7275 47.1565 51.3508 39.0246 52.7893 32.1091L53.8772 32.3354C52.3836 39.5152 47.6242 47.8278 40.3178 55.3668C36.9613 58.8303 33.0609 62.1372 28.6807 65.1022Z" fill="currentColor"/>
  </Icon>
)