import {useBreakpointValue, VStack} from "@chakra-ui/react";
import {Headline2, TextWithDot} from "../../../../../../../components";
import {s} from "../../../../../../../helper/common";
import {AdvantageSlider} from "./AdvantageSlider";


export const InfoBlock = (props) => {
  const isSliderHidden = useBreakpointValue(
    {base: true, lg: false},
    { fallback: 'lg' },
  )

  return (
    <VStack
      align="left"
      gap={s(["12px", "16px", "16px", "24px", "32px"])}
      {...props}
    >
      <TextWithDot
        text={'Unique solutions for a new market'}
        color={'green.600'}
      />
      <VStack
        align="left"
        gap={s(["24px", "64px", "64px", "80px", "96px"])}
      >
        <Headline2 maxWidth={s(['100%', "460px", "558px", "704px", "1014px"])}>
          cutting-edge and patented device lineup powered by resistive heating technology
        </Headline2>
        <AdvantageSlider hidden={isSliderHidden}/>
      </VStack>
    </VStack>
  )
}