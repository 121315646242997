import {Box, Flex, Img, useBreakpointValue} from "@chakra-ui/react";
import {s} from "../../../../../helper/common";
import {CFDTokenBigImg, functionalNutritionImg, lunchBoxer1, snackSprinterImg} from "../../../../assets/images";
import React from "react";
import {animated} from '@react-spring/web'

const Image = ({ src }) => {
  return (
    <Img
      src={src}
      objectFit={'cover'}
      w={'100%'}
      h={'100%'}
    />
  )
}
export const SnackSprinterImg = (props) => {
  const {
    snackTop,
    left = s(['15%', '17%', '15%', '18%', '15%'])
  } = props
  const leftVal = useBreakpointValue(left)
  return (
    <animated.div style={{
      position: 'relative',
      transition: 'top .1s',
      top: snackTop.to(value => `${value}%`),
      left: leftVal
    }}>
      <Box
        w={s(['88px', '200px', '240px', '302px', '382px'])}
        flexShrink={0}
      >
        <Image src={snackSprinterImg} />
      </Box>
    </animated.div>
  )
}
export const LunchBoxerImg = (props) => {
  const {
    lunchBottom,
    left = s(['5%', '6%', '4.5%', '8%', '5%'])
  } = props
  const leftVal = useBreakpointValue(left)
  return (
    <animated.div style={{
      position: 'relative',
      transition: 'bottom .1s',
      bottom: lunchBottom.to(value => `${value}%`),
      left: leftVal
    }}>
      <Box
        w={s(['140px', '320px', '370px', '484px', '588px'])}
        flexShrink={0}
      >
        <Image src={lunchBoxer1} />
      </Box>
    </animated.div>
  )
}
export const NutritionImg = (props) => {
  const {
    nutritionBottom,
    right = s(['5%', '5%', '5%', '2%', '5%'])
  } = props
  const rightVal = useBreakpointValue(right)
  return (
    <animated.div style={{
      position: 'relative',
      transition: 'bottom .1s',
      right: rightVal,
      bottom: nutritionBottom.to(value => `${value}%`)
    }}>
      <Box
        w={s(['96px', '217px', '250px', '331px', '401px'])}
        flexShrink={0}
      >
        <Image src={functionalNutritionImg} />
      </Box>
    </animated.div>
  )
}
export const TokenImg = (props) => {
  const {
    tokenTop,
    right = s(['15%', '17%', '15%', '13%', '16%'])
  } = props
  const rightVal = useBreakpointValue(right)
  return (
    <animated.div style={{
      position: 'relative',
      transition: 'bottom .1s',
      top: tokenTop.to(value => `${value}%`),
      right: rightVal
    }}>
      <Box
        w={s(['80px', '188px', '212px', '294px', '356px'])}
        flexShrink={0}
      >
        <Image src={CFDTokenBigImg} />
      </Box>
    </animated.div>
  )
}
export const WrapperImages = (props) => {
  return (
    <Flex
      gap={0}
      alignItems="end"
      justify={'center'}
      w={s(["304px", '736px', '867px', '1052px', '1274px'])}
      h={s(["192px", '440px', '492px', '636px', '686px'])}
      margin={'0 auto'}
      {...props}
    >
      {props.children}
    </Flex>
  )
}
