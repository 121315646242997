import {VStack} from "@chakra-ui/react";
import React, {useState} from "react";
import quickHeatingImg from "../../../../../cards/deviceContentCards/assets/quickHeating.png";
import {DeviceContentCard} from "../../../../../../../components/cards";
import pocketImg from "../../../../../cards/deviceContentCards/assets/pocket.png";
import batteryImg from "../../../../../cards/deviceContentCards/assets/battery.png";
import {s} from "../../../../../../../helper/common";

const data = [
  {
    id: '01',
    img: quickHeatingImg,
    title: "quick heating",
    description: "For snacking on the go and in situations where it's not possible to heat up food"
  },
  {
    id: '02',
    img: pocketImg,
    title: "fits into pocket",
    description: "Due to the device's compact size, it can easily be carried with you — it fits into a pocket, purse, or backpack",
  },
  {
    id: '03',
    img: batteryImg,
    title: "up to 24h battery life",
    description: "Optimal for taking to work or on flights",
  },
]

export const AdvantageSlider = (props) => {
  const [selectedId, setSelectedId] = useState(data[0].id)

  return (
    <VStack
      {...props}
      gap={s(["12px", "16px", "16px", "24px", "32px"])}
      align="left"
    >
      {
        data.map(cardItem => (
          <DeviceContentCard
            key={cardItem.id}
            isActive={selectedId === cardItem.id}
            onClick={() => setSelectedId(cardItem.id)}
            imgSrc={cardItem.img}
            num={cardItem.id}
            title={cardItem.title}
            description={cardItem.description}
          />
        ))
      }
    </VStack>
  )
}