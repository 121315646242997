import {spacings, viewSizes} from "../../../../../../config/theme/sizes";

export const sliderOptions = {
  centerMode: 0,
  type: 'loop',
  focus: 1,
  autoWidth: true,
  gap: '24px',
  pagination: false,
  breakpoints: {
    [viewSizes.wide]: {
      gap: "24px",
      padding: {
        left: '24px',
      }
    },
    [viewSizes.desktop]: {
      gap: "16px",
      padding: {
        left: '16px',
      }
    },
    [viewSizes.tablet]: {
      arrows: false,
      gap: '16px',
      padding: {
        left: '16px',
      }
    },
    [viewSizes.mobile]: {
      focus: 0,
      gap: '8px',
      padding: {
        left: '8px',
      }
    },
    [viewSizes.small]: {
      gap: '8px',
      padding: {
        left: '8px',
      }
    },
  }
}

export const sliderWrapperStyles = {
  '.splide': {
    marginInline: spacings.section.marginInlineOverflow
  },
  '.splide__arrow': {
    background: 'none',
    padding: '0',
    height: '130%',
    width: '20%',
    display: 'flex',
    alignItems: 'center'
  },
  '.splide__arrow--prev': {
    justifyContent: 'flex-start'
  },
  '.splide__arrow--next': {
    justifyContent: 'flex-end'
  },
  '.splide__arrow--prev svg': {
    transform: "none",
    height: 'auto'
  }
}