import {Icon} from "@chakra-ui/react";

export const DotIcon = (props) => (
  <Icon
    color={"currentColor"}
    {...props}
    viewBox="0 0 13 12"
  >
    <circle cx="6.5" cy="6" r="6" fill="currentColor"/>
  </Icon>
)