// для дублирования первых base
export const s = (styleArr) => {
  return [styleArr[0], ...styleArr]
}

// export const scrollToAnchor = (anchor) => {
export const scrollToAnchor = (query, behavior = 'smooth') => {
  document.querySelector(query)?.scrollIntoView({ behavior });
}


export const lerp = (value, sectionStart, sectionEnd, start, end) => {
  const ratio = (value - sectionStart) / (sectionEnd - sectionStart);
  return start + (end - start) * ratio;
}