import {CardAdvantage} from "../../../components/cards";
import {EcoIcon, NutritionIcon, TimerIcon} from "../../../components";

export const NutritionCardAdvantage = (props) => (
  <CardAdvantage
    IconComponent={NutritionIcon}
    title={"personalized nutrition for modern lifestyles"}
    description={"Еvery individual has unique dietary needs and busy schedules. With our future brand's line of functional food you can enjoy nourishing meals that seamlessly integrate into your daily routine. Take control of your health and well-being with Cryptofood's® personalized nutrition solutions"}
    bgColor={'green.300'}
    textColor={'white'}
    {...props}
  />
)
export const TimerCardAdvantage = (props) => (
  <CardAdvantage
    IconComponent={TimerIcon}
    title={"time-saving convenience at your fingertips"}
    description={"Say goodbye to lengthy meal preparation and hello to time-saving convenience. Our devices are designed to fit seamlessly into any lifestyle, providing quick and efficient solutions for busy individuals. Enjoy hot and ready meals in minutes, giving you more time to focus on what truly matters"}
    bgColor={'green.200'}
    textColor={'green.900'}
    {...props}
  />
)
export const EcoCardAdvantage = (props) => (
  <CardAdvantage
    IconComponent={EcoIcon}
    title={"sustainable dining made easy"}
    description={"At Cryptofood®, we believe in sustainability and eco-friendly practices. Our devices are designed with the environment in mind, using energy-efficient technology and minimizing waste. Join us in reducing our carbon footprint while still enjoying meals"}
    bgColor={'green.50'}
    textColor={'green.900'}
    {...props}
  />
)