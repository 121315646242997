
export const sliderCardsData = [
  {
    id: '1',
    title: "share, spread the word and earn",
    description: "Recommend us, write reviews, and engage to increase your token holdings and exchange them for devices when available.",
  },
  {
    id: '2',
    title: "ride the wave of token growth",
    description: "Profits await as Cryptofood tokens gain value over time. Participate and show interest in our project, and you can enjoy real returns",
  },
  {
    id: '3',
    title: "be the first to earn and spend",
    description: "Earn tokens, subscribe to news, and complete tasks to be among the first to own a Cryptofood® device",
  },
  {
    id: '4',
    title: "stay connected\nwith us",
    description: "Get exclusive updates and be part of our community before the official launch",
  }
]