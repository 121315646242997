import React, {useEffect} from 'react';
import {Box, Flex, useBreakpointValue, useDisclosure} from "@chakra-ui/react";
import {HamburgerIcon, ArrowRightIcon, LinkButtonSmall, LogoIcon} from "../../../components";
import {MobileMenu} from "../MobileMenu/MobileMenu";
import {NavMenu} from "../NavMenu/NavMenu";
import {s} from "../../../helper/common";
import {networksLinks} from "../../../config/links";
import {animated} from "@react-spring/web";


export const Header = ({ opacity }) => {
  const isMobile = useBreakpointValue(
    {base: true, md: false},
    { fallback: 'md' },
  )
  const {isOpen: mobileMenuIsOpen, onOpen, onClose} = useDisclosure()

  useEffect(() => {
    if (!isMobile) {
      onClose()
    }
  }, [isMobile])

  return (
    <>
      <animated.div style={{ opacity, visibility: opacity.to(value => value ? 'visible' : 'hidden') }}>
        <Flex
          flexShrink={0}
          align="center"
          justify={'space-between'}
          p={s(['8px 8px 0', '16px 16px 0', '16px 16px 0', '20px 16px 0', '24px 24px 0'])}
          position={'relative'}
          zIndex={100}
        >
          <NavMenu hidden={isMobile}/>
          <Box
            as={'button'}
            onClick={onOpen}
            hidden={!isMobile}
            flexShrink="0"
          >
            <HamburgerIcon
              w={'16px'}
              h={'16px'}
              color={'grey.900'}
            />
          </Box>

          <LogoIcon />

          <Box minWidth={'24px'}>
            <LinkButtonSmall
              hidden={isMobile}
              rightIcon={<ArrowRightIcon/>}
              href={networksLinks.telegram}
            >SIGN UP FOR THE UPDATES</LinkButtonSmall>
          </Box>
        </Flex>
      </animated.div>
      <MobileMenu
        isOpen={mobileMenuIsOpen && isMobile}
        onClose={onClose}
      />
    </>
  );
};
