import React, {useEffect, useRef, useState} from 'react';
import {useScroll} from '@react-spring/web';
import {
  DevicesSection,
  EcosystemSection,
  Footer,
  Header,
  InitialSection,
  MissionSection,
  NewsSection,
  RoadmapSection,
  TokenSection
} from "./components";
import {Box} from "@chakra-ui/react";
import {
  useEcosystemAnimations,
  useMeetTheLunchboxerAnimations,
  useRoadmapAnimations,
  useTokenAnimations
} from "./animations";
import {s} from "../helper/common";
import {animated} from '@react-spring/web'
import {viewSizes} from "../config/theme/sizes";


const Landing = () => {
  const scroll = useScroll();
  const scrollYProgress = scroll.scrollYProgress;

  useEffect(() => {
    const handlePressBtn = (e) => {
      /** Ускорение скролла */
      const currentWidth = window.innerWidth;
      const deskSize = currentWidth > viewSizes.tablet
      if (!deskSize) return

      const scrollValue = 800
      if (e.key === 'ArrowUp') {
        e.preventDefault()
        window.scrollBy({left: 0, top: -scrollValue, behavior: 'smooth'});
      }
      if (e.key === 'ArrowDown') {
        e.preventDefault()
        window.scrollBy({left: 0, top: scrollValue, behavior: 'smooth'});
      }
   }

    window.addEventListener('keydown', handlePressBtn)
    return () => {
      window.removeEventListener('keydown', handlePressBtn)
    }
  }, [])


  const refHeaderWrapper = useRef(null)

  const {
    snackPosTop,
    lunchPosBottom,
    nutritionPosBottom,
    tokenPosTop,
    ecosystemBlur,
    ecosystemOpacity,
    descriptionsOpacity,
    titleOpacity,
    headerOpacity,
  } = useEcosystemAnimations({scrollYProgress})

  const {
    titleOpacity: roadmapTitleOpacity,
    card1Opacity: roadmapCard1Opacity,
    card2Opacity: roadmapCard2Opacity,
    card3Opacity: roadmapCard3Opacity,

    progressIcon1Opacity: roadmapProgressIcon1Opacity,
    progressIcon2Opacity: roadmapProgressIcon2Opacity,
    progressIcon3Opacity: roadmapProgressIcon3Opacity,

    progressLine1Width: roadmapProgressLine1Width,
    progressLine2Width: roadmapProgressLine2Width,
  } = useRoadmapAnimations({scrollYProgress})

  const tokenAnimation = useTokenAnimations({scrollYProgress})

  return (
    <Box
      h={'100dvh'}
      minWidth={'320px'}
      display={'flex'}
      flexDirection={'column'}
      justify={'space-between'}
    >
      <Box h={'fit-content'}>
        <Box h={s(['3700px', '4300px', '4300px', "4600px", '5500px'])}>
          <Box
            ref={refHeaderWrapper}
            position={'sticky'}
            zIndex={s(['100', '1'])}
            top={'0'}
            w={'100%'}
          >
            <Header opacity={headerOpacity}/>
            <InitialSection opacity={titleOpacity}/>
          </Box>

          <EcosystemSection
            sectionBlur={ecosystemBlur}
            sectionOpacity={ecosystemOpacity}
            descriptionsOpacity={descriptionsOpacity}
            snackTop={snackPosTop}
            lunchBottom={lunchPosBottom}
            nutritionBottom={nutritionPosBottom}
            tokenTop={tokenPosTop}
            refHeaderWrapper={refHeaderWrapper}
          />
          <Box
            position={'sticky'}
            top={["110vh"]}
            zIndex={['100', '1']}
          >
            <MissionSection/>
          </Box>
        </Box>
        <TokenSection
          tokenAnimation={tokenAnimation}
        />
        <DevicesSection
          scrollYProgress={scrollYProgress}
        />

        <RoadmapSection
          titleOpacity={roadmapTitleOpacity}

          card1Opacity={roadmapCard1Opacity}
          card2Opacity={roadmapCard2Opacity}
          card3Opacity={roadmapCard3Opacity}

          progressIcon1Opacity={roadmapProgressIcon1Opacity}
          progressIcon2Opacity={roadmapProgressIcon2Opacity}
          progressIcon3Opacity={roadmapProgressIcon3Opacity}

          progressLine1Width={roadmapProgressLine1Width}
          progressLine2Width={roadmapProgressLine2Width}
        />

        <NewsSection/>

        {/**/}
        {/*<TestSection />*/}
      </Box>
      <Footer/>

      {/* элемент для трекинга значения скролла из хука */}
      {/*<animated.div*/}
      {/*  style={{*/}
      {/*    // opacity: scrollYProgress,*/}
      {/*    position: "fixed",*/}
      {/*    background: "red",*/}
      {/*    zIndex: 9999,*/}
      {/*    width: 100,*/}
      {/*    alignItems: "center",*/}
      {/*    display: "flex",*/}
      {/*    justifyContent: "center",*/}
      {/*    height: 100,*/}
      {/*    bottom: 0,*/}
      {/*    left: 0,*/}
      {/*  }}*/}
      {/*>{scrollYProgress.to(value => value.toFixed(5))}</animated.div>*/}
    </Box>
  );
}

export default Landing;
