import {Box, Fade, VStack} from "@chakra-ui/react";
import {Body1, DescriptionBlock, DualBlock, Headline1, TextWithDot} from "../../../../../../components";
import {s} from "../../../../../../helper/common";
import {dataInfo, deviceSectionStartAnchorId} from "../../data";
import {devicesEnum} from "../../enum";
import React from "react";
import {animated} from '@react-spring/web'


export const DeviceDescriptionBlock = (props) => {
  const {
    selectedDeviceKey = devicesEnum.lunch,
    lunchboxerAnimation
  } = props

  const correctData = dataInfo[selectedDeviceKey]

  return (
    <Fade
      in={true}
      delay={{enter: 0.4, exit: 0}}
    >
      <VStack
        id={deviceSectionStartAnchorId}
        gap={s(["12px", "16px", "16px", "24px", "32px"])}
      >
        <TextWithDot
          text={'explore the power of cryptofood® technology'}
          color={'green.700'}
        />
        <Headline1
          align="center"
        >
          meet the <br/> {correctData.title}
        </Headline1>
        <VStack
          gap={s(["56px", "72px", "72px", "96px", "120px"])}
          w={'100%'}
        >
          <animated.div style={{
            position: 'relative',
            top: lunchboxerAnimation.to(value => `${value}px`),
            transition: 'top .03s ease-in-out'
          }}>
            {correctData.img}
            <Box
              position={'absolute'}
              left={0}
              right={0}
              top={0}
              bottom={0}
              margin={'auto'}
              w={s(["152px", "320px", "374px", "504px", "600px"])}
              h={s(["200px", "392px", "458px", "614px", "734px"])}
            >
              <Box
                position={"absolute"}
                {...correctData.deviceInfo.btn.pos}
              >
                <DescriptionBlock
                  direction={'row-reverse'}
                  wrapperDirection={'column-reverse'}
                  description={correctData.deviceInfo.btn.text}
                  textAlign={"right"}
                  lineW={correctData.deviceInfo.btn.lineW}
                  containerAlignItems={s(['end'])}
                />
              </Box>

              {/* ==================== */}

              <Box
                position={"absolute"}
                {...correctData.deviceInfo.port.pos}
              >
                <DescriptionBlock
                  wrapperDirection={'column-reverse'}
                  description={correctData.deviceInfo.port.text}
                  lineW={correctData.deviceInfo.port.lineW}
                />
              </Box>

              {/* ==================== */}

              <Box
                position={"absolute"}
                {...correctData.deviceInfo.bottom.pos}
              >
                <DescriptionBlock
                  direction={'row-reverse'}
                  wrapperDirection={'column-reverse'}
                  description={correctData.deviceInfo.bottom.text}
                  textAlign={"right"}
                  textW={correctData.deviceInfo.bottom.maxWidth}
                  containerAlignItems={s(['end'])}
                  lineW={correctData.deviceInfo.bottom.lineW}
                  gap={s(['12px', '24px', '24px', '16px', '32px'])}
                />
              </Box>
            </Box>
          </animated.div>


          <DualBlock
            alignSelf={'left'}
            // templateColumns={s(['1fr', '92px 1fr', '248px 1fr', '439px 1fr', '546px 1fr'])}
            templateColumns={s(['1fr', '1fr 1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr',])}
            left={<Box/>}
            right={
              <Body1
                w={s(["100%", "552px", "496px", "530px", "740px"])}
              >
                {correctData.description}
              </Body1>
            }
          />
        </VStack>
      </VStack>
    </Fade>
  )
}