import {s} from "../../../../../helper/common";
import {getGradationStep} from "../utils";
import {Box, HStack} from "@chakra-ui/react";
import {CoinIcon, DeviceIcon, FoodIcon} from "../../../../../components";
import React from "react";
import {themeColors} from "../../../../../config/theme/colors";
import {animated} from '@react-spring/web'

const Line = ({progressWidth}) => {
  return (
    <Box position={'relative'} w={'100%'}>
      <Box
        h={"1px"}
        w={'100%'}
        bg={'grey.500'}
      />
      <animated.div style={{
        width: progressWidth.to(val => val < 1 ? `0%` : `${val}%`),
        transition: 'width .2s',
        height: '1px',
        position: 'absolute',
        background: themeColors.grey["50"]
      }}/>
    </Box>
  )
}

export const LineComponent = (props) => {
  const {
    stepNum,

    progressIcon1Opacity,
    progressIcon2Opacity,
    progressIcon3Opacity,

    progressLine1Width,
    progressLine2Width
  } = props

  const iconSize = s(["24px", "40px", "40px", "56px", "64px"])
  const color = 'grey.50'

  return (
    <HStack
      gap={s(["4px", "6px",])}
      justify={'space-between'}
      align="center"
      color={color}
    >
      <animated.div style={{
        opacity: progressIcon1Opacity.to(val => val < 0.5 ? .5 : val),
        transition: 'opacity .2s',
      }}>
        <CoinIcon
          w={iconSize}
          h={iconSize}
          color={color}
        />
      </animated.div>

      <Line progressWidth={progressLine1Width}/>

      <animated.div style={{
        opacity: progressIcon2Opacity.to(val => val < 0.5 ? .5 : val),
        transition: 'opacity .2s',
      }}>
        <DeviceIcon
          w={iconSize}
          h={iconSize}
          color={color}
        />
      </animated.div>

      <Line progressWidth={progressLine2Width}/>

      <animated.div style={{
        opacity: progressIcon3Opacity.to(val => val < 0.5 ? .5 : val),
        transition: 'opacity .2s',
      }}>
        <FoodIcon
          w={iconSize}
          h={iconSize}
          color={color}
        />
      </animated.div>
    </HStack>
  )
}