import {s} from "../../../../../../../helper/common";
import {Box, Image} from "@chakra-ui/react";
import snackWithArmImgImg from "../../../../../../assets/images/snackSprinterWithArmImg.png";
import {DevicesCard} from "../../../../../../../components/cards";
import React from "react";


export const SnakArm = ({onClick}) => {

  const snakImgW = s(["282px", "627px", "750px", "991px", "1021px"])
  return (
    <Box
      position={'absolute'}
      w={'100%'}
      top={s(['250px', "100px", "-60px", "-60px"])}
      left={s(['-16px', '-32px', '-86px', '-102px'])}
    >
      <Image
        src={snackWithArmImgImg}
        minW={snakImgW}
        maxW={snakImgW}
        h={s(["279px", "620px", "742px", "981px", "1010px"])}
        objectFit={'contain'}
        position={'absolute'}
        top={s(["-30px"])}
        left={s([0])}
      />
      <DevicesCard
        zIndex={1}
        title={'snacksprinter'}
        description={'Compact Heating Solution for Heating Snacks in a Flash'}
        position={'absolute'}
        top={s(['160px', '280px', '330px', '480px'])}
        left={s(['22px', "210px", "230px", "290px"])}
        onClick={onClick}
      />
    </Box>
  )
}