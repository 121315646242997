import {getCurrentStep, getGradationStep} from "../utils";
import {Box, VStack} from "@chakra-ui/react";
import {s} from "../../../../../helper/common";
import {Body1, Headline2, TextWithDot} from "../../../../../components";
import React from "react";
import {animated} from '@react-spring/web'


export const ContentBlock = (props) => {
  const {
    titleOpacity,
    isException,
    stepNum
  } = props

  const {
    isFirst, isSecond, isThird, isFourth
  } = getCurrentStep(stepNum)
  const {
    moreFirst, moreSecond, moreThird, moreFourth
  } = getGradationStep(stepNum)


  return (
    <Box>
      <VStack
        gap={s(['12px', "16px", "16px", "24px", "32px"])}
        align="left"
        w={s(["304px", "682px", "880px", "1092px"])}
      >
        <animated.div style={{
          opacity: titleOpacity
        }}>
          <TextWithDot
            hidden={!isException}
            text={'product roadmap'}
            color={'grey.200'}
          />

          <VStack
            gap={s(['8px', "16px", "16px", "24px", "32px"])}
            align="left"
          >
            <Headline2 color={'grey.50'}>
              a new ecosystem is here. <br/>
              are you ready to take a bite?
            </Headline2>
            <Body1
              color="grey.200"
              w={s(["100%", "558px", "558px", "616px", "702px"])}
            >
              Fuel your curiosity, satisfy your taste buds, and embark on a yourney to revolutionize your relationship
              with
              food. The Cryptofood® Project Poadmap is your gateway to a future where nutrition meets innovation
            </Body1>
          </VStack>
        </animated.div>
      </VStack>
    </Box>
  )
}