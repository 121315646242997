
import { Headline2, TextWithDot } from "../../../../components";
import { themeColors } from "../../../../config/theme/colors";
import { Box } from '@chakra-ui/react'
import styled from "@emotion/styled";
import { s } from "../../../../helper/common";
import { EcoCardAdvantage, NutritionCardAdvantage, TimerCardAdvantage } from "../../cards/advantageCards";


const Content = styled.div`
    width: calc(100% - 16px * 2);
    gap: 16px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    position: -webkit-sticky;
    position: sticky;
    //height: 356px;
    top: 22%;
    margin: 0;
    overflow: visible;
    transform: none;
  
    @media (max-width: 767px) {
        height: 356px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        height: 482px;
    }

`

const Cards = styled.div`
    width: 100%;
    display: grid;
    grid-template: repeat(4, min-content) / repeat(6, 1fr);
    overflow: visible;
    transform: none;
    padding-top: 400px;
  
    @media (max-width: 767px) {
        grid-template: repeat(3, min-content) / 1fr;
    }

`

export const MissionSection = (props) => {
    return (
        <Box p={s([
            "56px 8px 56px 16px",
            "64px 16px 56px 16px",
            "88px 16px 88px 16px",
            "160px 16px 160px 16px",
            "160px 24px 160px 16px"
        ])}
            width={'100%'}
            position={'relative'}
            margin={'auto'}
        >
            <Content>
                <TextWithDot
                    text={'Unique solutions for a new market'}
                    color={'green.700'}
                />
                <Headline2>
                    by seamlessly merging traditional dining with cutting-edge blockchain technologies and foodtech
                    advancements,
                    <span style={{ color: themeColors.green["300"] }}> we offer a lifestyle that prioritizes health and flexibility</span>
                </Headline2>
            </Content>
            <Cards>
                <NutritionCardAdvantage
                    rotation={s(["-4deg"])}
                    zIndex={s(["1"])}
                    gridArea={s(['1 / 1', '1 / 1 / 2 / 4'])}
                    position={'sticky'}
                    top={s(['35%', '35%', '35%'])}
                />
                <EcoCardAdvantage
                    rotation={s(["6deg", "-6deg"])}
                    zIndex={s(["2"])}
                    gridArea={s(['2 / 1', '2 / 4 / 3 / 7'])}
                    justifySelf={'end'}
                    position={'sticky'}
                    top={s(['45%', '30%', '30%'])}
                />
                <TimerCardAdvantage
                    rotation={s(["-6deg", "6deg"])}
                    zIndex={s(["3"])}
                    gridArea={s(['3 / 1', '4 / 2 / 5 / 6'])}
                    justifySelf={'center'}
                    position={'sticky'}
                    top={s(['45%', '45%', '30%'])}
                />
            </Cards>
        </Box>
    )
}
