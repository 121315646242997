import React from 'react';
import {Center} from "@chakra-ui/react";
import {s} from "../../../../helper/common";
import {Title} from "./components/Title";
import {animated} from "@react-spring/web";

export const InitialSection = ({opacity}) => {
  return (
    <animated.div style={{ opacity }}>
      <Center
        color={"green.900"}
        align="center"
        overflow={'hidden'}
        position={'relative'}
        p={s(["40px 0 0", "72px 0 0", "64px 0 0", "80px 0 0", "96px 0 80px"])}
      >
        <Title/>
      </Center>
    </animated.div>
  )
};

