import {getCurrentStep, getGradationStep} from "../utils";
import {Box, Flex} from "@chakra-ui/react";
import {spacings} from "../../../../../config/theme/sizes";
import {s} from "../../../../../helper/common";
import React from "react";
import {LineComponent} from "./LineComponent";
import {ContentBlock} from "./ContentBlock";
import {StepCardBlock} from "./StepCardBlock";


export const StepComponent = (props) => {
  const {
    titleOpacity,

    card1Opacity,
    card2Opacity,
    card3Opacity,

    progressIcon1Opacity,
    progressIcon2Opacity,
    progressIcon3Opacity,

    progressLine1Width,
    progressLine2Width,

    stepNum = 1,
    isException,
    ...restProps
  } = props

  return (
    <Flex
      bg={'grey.900'}
      p={spacings.section.second}
      // h={s(["100dvh", "100dvh", "720px", "800px", "900px"])}
      h={s(["100dvh"])}
      flexDirection={"column"}
      justifyContent={"space-between"}
      height="100%"
      {...restProps}
    >
      <ContentBlock
        titleOpacity={!isException ? titleOpacity : null}
        isException={isException}
        stepNum={stepNum}
      />
      <Flex
        flexDirection={'column'}
        gap={s(["12px", "24px", "32px", "32px", "32px"])}
      >
        <Flex position={"relative"}>
          <StepCardBlock opacity={card1Opacity} stepNum={2}/>
          <StepCardBlock opacity={card2Opacity} stepNum={3}/>
          <StepCardBlock opacity={card3Opacity} stepNum={4}/>
        </Flex>
        <LineComponent
          stepNum={1}

          progressIcon1Opacity={progressIcon1Opacity}
          progressIcon2Opacity={progressIcon2Opacity}
          progressIcon3Opacity={progressIcon3Opacity}

          progressLine1Width={progressLine1Width}
          progressLine2Width={progressLine2Width}
        />
      </Flex>
    </Flex>
  )
}