import React, {useRef, useState} from 'react';
import {Box} from "@chakra-ui/react";
import {FirstSolutionsBlock} from "./components/FirstSolutionsBlock/FirstSolutionsBlock";
import {ChooseBlock} from "./components/ChooseBlock/ChooseBlock";
import {DeviceDescriptionBlock} from "./components/DeviceDescriptionBlock/DeviceDescriptionBlock";
import {devicesEnum} from "./enum";
import {DevicesHeader} from "./components/DevicesHeader";
import {useIntersection} from "../../../../hooks/useIntersection";
import {navigationKeys} from "../../../../config/navigation";
import {SliderBlock} from "./components/SliderBlock/SliderBlock";
import {HowToUseBlock} from "./components/HowToUseBlock/HowToUseBlock";
import {spacings} from "../../../../config/theme/sizes";
import {useHowToUseAnimations, useMeetTheLunchboxerAnimations} from "../../../animations";
import {dataSteps} from "./components/HowToUseBlock/data";
import {s, scrollToAnchor} from "../../../../helper/common";
import {deviceSectionStartAnchorId} from "./data";


export const DevicesSection = (props) => {
  const {
    scrollYProgress
  } = props
  const refIndicator = useRef(null)
  const refContainer = useRef(null)
  const [isOverlapping] = useIntersection(refContainer, refIndicator)

  const [selectedDeviceKey, setSelectedDeviceKey] = useState(devicesEnum.lunch)

  const elementsData = dataSteps[selectedDeviceKey]

  const selectNewDeviceWithScroll = (key) => {
    setSelectedDeviceKey(key)
    scrollToAnchor(`#${deviceSectionStartAnchorId}`, 'instant')
  }

  const {
    stepsOpacities
  } = useHowToUseAnimations({scrollYProgress, elementsArr: elementsData.steps})

  const lunchboxerAnimation = useMeetTheLunchboxerAnimations({scrollYProgress})

  return (
    <Box id={navigationKeys.devices.key} zIndex={4}>
      <Box
        ref={refIndicator}
        position={'fixed'}
        w={'100%'}
        h={'1px'}
        top={0}
        left={0}
        zIndex={0}
      />

      <FirstSolutionsBlock/>
      <ChooseBlock
        setSelectedDeviceKey={setSelectedDeviceKey}
      />
      <Box
        ref={refContainer}
      >
        <DevicesHeader
          isOpen={isOverlapping}
          selectedDeviceKey={selectedDeviceKey}
          setSelectedDeviceKey={selectNewDeviceWithScroll}
        />
        <Box p={spacings.section.main}>
          <DeviceDescriptionBlock
            lunchboxerAnimation={lunchboxerAnimation}
            selectedDeviceKey={selectedDeviceKey}
          />
        </Box>
        <Box height={s(["2200px","2200px","2500px","3000px","3500px"])}>
          <HowToUseBlock
            stepsOpacities={stepsOpacities}
            elementsData={elementsData}
          />
        </Box>
      </Box>
      <SliderBlock />
    </Box>
  );
};
