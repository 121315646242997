import {Icon} from "@chakra-ui/react";

export const TimerIcon = (props) => (
  <Icon
    color="currentColor"
    {...props}
    viewBox="0 0 80 80"
  >
    <path
      d="M20.747 62.7412C15.6431 57.7393 12.7778 50.9577 12.7778 43.8889L11.6667 43.8889C11.6667 51.2597 14.6546 58.3263 19.9694 63.5347C25.2837 68.7428 32.4892 71.6667 40 71.6667L40 70.5556C32.7767 70.5556 25.8514 67.7434 20.747 62.7412Z"
      fill="currentColor"/>
    <path
      d="M44.6107 70.1703C48.6669 69.4875 52.5118 67.9126 55.8564 65.5648L56.4948 66.4742C53.0138 68.9178 49.0137 70.5558 44.7951 71.266L44.6107 70.1703Z"
      fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M44.4445 42.2223C44.4445 44.6768 42.4547 46.6667 40.0001 46.6667C37.5455 46.6667 35.5556 44.6768 35.5556 42.2223C35.5556 39.7677 37.5455 37.7778 40.0001 37.7778C41.0271 37.7778 41.9728 38.1262 42.7254 38.7112L53.4961 27.9405L54.2818 28.7262L43.5111 39.4969C44.0961 40.2495 44.4445 41.1952 44.4445 42.2223ZM43.3334 42.2223C43.3334 44.0632 41.841 45.5556 40.0001 45.5556C38.1591 45.5556 36.6667 44.0632 36.6667 42.2223C36.6667 40.3813 38.1591 38.8889 40.0001 38.8889C41.841 38.8889 43.3334 40.3813 43.3334 42.2223Z"
          fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M27.7778 3.88892L27.7778 5.00003L39.4445 5.00003L39.4445 11.1111L40.0001 11.1111C22.2042 11.1111 7.77783 25.5375 7.77783 43.3334C7.77783 61.1292 22.2042 75.5556 40.0001 75.5556C57.7959 75.5556 72.2223 61.1292 72.2223 43.3334C72.2223 25.5375 57.7959 11.1111 40.0001 11.1111L40.5556 11.1111L40.5556 5.00003L52.2223 5.00003L52.2223 3.88892L27.7778 3.88892ZM71.1112 43.3334C71.1112 60.5156 57.1822 74.4445 40.0001 74.4445C22.8179 74.4445 8.88894 60.5156 8.88894 43.3334C8.88894 26.1512 22.8179 12.2223 40.0001 12.2223C57.1822 12.2223 71.1112 26.1512 71.1112 43.3334Z"
          fill="currentColor"/>
  </Icon>
)