import {Stack} from "@chakra-ui/react";
import {navigationKeys} from "../../../config/navigation";
import {LinkButtonSmall} from "../../../components";
import React from "react";
import {s, scrollToAnchor} from "../../../helper/common";

export const NavMenu = (props) => {
  const {
    isMobile,
    onCloseMenu,
    spacing = s(['32px', '32px', '40px', '40px', '48px']),
    direction = s(['column', 'row', 'row', 'row', 'row']),
    ...rest
  } = props

  const nav = Object.values(navigationKeys)

  const clicKToMenu = (e) => {
    e.preventDefault();
    if (isMobile) {
      onCloseMenu()
    }
    scrollToAnchor(e.target.getAttribute('href'))
  }
  return (
    <Stack
      spacing={spacing}
      direction={direction}
      {...rest}
    >
      {nav.map((navEl) =>
        <LinkButtonSmall
          key={navEl.key}
          href={`#${navEl.key}`}
          onClick={clicKToMenu}
        >
          {navEl.title}
        </LinkButtonSmall>
      )}
    </Stack>
  )
}