import {Icon} from "@chakra-ui/react";

export const CloseIcon = (props) => (
  <Icon
    color={"currentColor"}
    {...props}
    viewBox="0 0 48 48"
  >
    <path
      d="M34.6059 36.0208L36.0201 34.6066L25.4136 24.0001L36.0203 13.3934L34.6061 11.9792L23.9994 22.5859L13.3927 11.9792L11.9785 13.3934L22.5852 24.0001L11.9787 34.6066L13.3929 36.0208L23.9994 25.4143L34.6059 36.0208Z"
      fill="currentColor"/>
  </Icon>
)