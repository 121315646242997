import {Box, Image, useBreakpointValue} from "@chakra-ui/react";
import React from "react";
import {s} from "../../../../../helper/common";
import {CFDTokenBigImg} from "../../../../assets/images";
import {animated} from "@react-spring/web";


export const ImageTokenBlock = (props) => {
  const {
    tokenAnimation
  } = props

  const width = useBreakpointValue(s(['200px', '400px', '471px', '557px', '628px']))
  // const mt = useBreakpointValue(s(['-25px', "-50px", "-55px", "-65px", "-75px"]))
  return (
    <animated.div style={{
      position: 'relative',
      width,
      marginTop: tokenAnimation.to(value => `-${value}px`),
      transition: 'margin-top .03s ease-in-out'
    }}>
      <Image src={CFDTokenBigImg}/>
    </animated.div>
  )
}