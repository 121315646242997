import React from 'react';
import {Box, useBreakpointValue} from "@chakra-ui/react";
import {StepComponent} from "./components/StepComponent";
import {s} from "../../../../helper/common";


export const RoadmapSection = (props) => {
  const {
    titleOpacity,

    card1Opacity,
    card2Opacity,
    card3Opacity,

    progressIcon1Opacity,
    progressIcon2Opacity,
    progressIcon3Opacity,

    progressLine1Width,
    progressLine2Width
  } = props

  const isException = useBreakpointValue(
    {base: false, md: true, lg: false},
    {fallback: 'md'},
  )

  return (
    <Box
      bg={'grey.900'}
      h={s(['2000px', '2000px' ,'2500px', '3000px', '3500px'])}
    >
      <StepComponent
        titleOpacity={titleOpacity}

        card1Opacity={card1Opacity}
        card2Opacity={card2Opacity}
        card3Opacity={card3Opacity}

        progressIcon1Opacity={progressIcon1Opacity}
        progressIcon2Opacity={progressIcon2Opacity}
        progressIcon3Opacity={progressIcon3Opacity}

        progressLine1Width={progressLine1Width}
        progressLine2Width={progressLine2Width}

        isException={isException}
        position={'sticky'}
        top={'0px'}
      />
    </Box>
  );
};


