import React from "react";
import {componentsSizeTheme} from "../config/theme/sizes";
import {Tabs as ChakraTabs, Tab as ChakraTab, TabList as ChakraTabList} from "@chakra-ui/react";

export const Tabs = (props) => (
  <ChakraTabs
    {...props}
    children={props.children}
  />
)
export const TabList = (props) => (
  <ChakraTabList
    {...props}
  />
)
export const Tab = (props) => (
  <ChakraTab
    {...props}
    {...componentsSizeTheme.Body1}
    as={'button'}
  />
)