import React from 'react';
import {Box, Button, Stack, useBreakpointValue} from "@chakra-ui/react";
import {accentColor} from "../../../config/theme/colors";
import {
  ArrowRightIcon,
  Body1,
  Body2,
  Caption1,
  DualBlock,
  Headline1,
  LinkButtonSmall,
  RSymbol,
  Subhead1
} from "../../../components";
import {s} from "../../../helper/common";
import {networksLinks} from "../../../config/links";

export const Footer = (props) => {
  const isSmallMobile = useBreakpointValue(
    {base: true, md: false},
    {fallback: 'sm'},
  )
  const leftBoxStyle = {
    w: s(['100%', '100%', 'fit-content'])
  }
  const rightBoxStyle = {
    pt: s(["0","0", "14px", "14px", '14px'])
  }

  return (
    <Stack
      flexShrink={0}
      bg={accentColor}
      overflow={'hidden'}
      justify={'space-between'}
      p={s(['16px 8px 12px', '24px 16px 16px', '24px 16px 16px', '40px 16px 24px', '56px 24px 32px'])}
      h={s(['328px', '362px', '322px', '434px', '528px'])}
    >
      <DualBlock
        gap={s(["16px", "16px", "0"])}
        templateRows={s(['auto 1fr', 'auto 1fr', '1fr'])}
        templateColumns={s(['1fr', '1fr', '434px 1fr', '616px 1fr', '780px 1fr'])}
        left={
          <Box {...leftBoxStyle}>
            <Headline1>cryptofood<RSymbol/></Headline1>
          </Box>
        }
        right={
          <Box {...rightBoxStyle}>
            <Subhead1
              color={'grey.50'}
            >
              are you full of ideas and suggestions?
            </Subhead1>

            <Caption1
              w={s(['100%', '368px', '352px', '528px', '702px'])}
              mt={s(['8px', '12px', '12px', '16px', '24px'])}
            >
              don't miss out on your chance to contribute and make a real impact. follow, subscribe and let's create
              something amazing together
            </Caption1>

            <Stack
              gap={s(['4px', '19px', '24px', '27px', '30px'])}
              direction={{sm: "column", base: "row", md: "row"}}
              mt={s(['12px', '24px', '24px', '40px', '56px'])}
              align={'baseline'}
            >
              <LinkButtonSmall
                textTransform={'uppercase'}
                rightIcon={<ArrowRightIcon/>}
                href={networksLinks.telegram}
                children={'Telegram Channel'}
              />
              <LinkButtonSmall
                textTransform={'uppercase'}
                rightIcon={<ArrowRightIcon/>}
                href={networksLinks.telegram_group}
                children={'Group for discussion'}
              />
              <LinkButtonSmall
                textTransform={'uppercase'}
                rightIcon={<ArrowRightIcon/>}
                href={networksLinks.twitter}
                children={'X (twitter)'}
              />
            </Stack>
          </Box>
        }
      />

      <Stack
        justify={'space-between'}
        direction={{sm: "column", base: "row", md: "row"}}
        align={'end'}
      >
        <DualBlock
          left={
            <Box {...leftBoxStyle}>
              <Body1
                hidden={isSmallMobile}
                w={s(['100%', '322px', '257px', '295px', '330px'])}
              >
                © 2023 Cryptofood®. All Rights Reserved.
                Cryptofood® is a registered trademark.
              </Body1>
              {/* Mobile */}
              <Body1
                hidden={!isSmallMobile}
                w={s(['100%', '322px', '257px', '295px', '330px'])}
              >
                © 2023 Cryptofood®.<br/>
                All Rights Reserved.<br/>
                Cryptofood® is a registered trademark.
              </Body1>
            </Box>
          }
          right={
            <Stack
              justify={'space-between'}
              direction={"row"}
              align="end"
              {...rightBoxStyle}
            >
              <Body2
                color={'green.900'}
              >
                info@cryptofood.io
                {/*PRIVACY POLICY*/}
              </Body2>
              <LinkButtonSmall href={'https://annti.design/'}>
                DESIGNED BY ANNTI
              </LinkButtonSmall>
            </Stack>
          }
        />
      </Stack>
    </Stack>
  );
};
