import {s} from "../../../../../../../helper/common";
import {Box, Image} from "@chakra-ui/react";
import lunchWithArmImg from "../../../../../../assets/images/lunchBoxerWithArm.png";
import {DevicesCard} from "../../../../../../../components/cards";
import React from "react";


export const LunchArm = ({onClick}) => {
  const lunchImgW = s(["438px", "828px", "1206px", "1509px", "1916px"])
  return (
    <Box
      position={'absolute'}
      w={'100%'}
      top={s(["-20px"])}
      right={s(["0"])}
    >
      <Image
        src={lunchWithArmImg}
        minW={lunchImgW}
        maxW={lunchImgW}
        h={s(["292px", "552px", "804px", "1007px", "1278px"])}
        objectFit={'contain'}
        position={'absolute'}
        top={s(["-70px", "-150px", "-190px", "-190px", "-300px"])}
        right={s(["-70px", "-90px", "-200px", "-160px", "-190px"])}
      />
      <DevicesCard
        title={'lunchboxer'}
        description={'Portable Device for Enjoying Proper Meals on the Fly'}
        position={'absolute'}
        zIndex={1}
        top={s(["130px", "60px", "90px", "180px", ""])}
        right={s(["8px", "16px", "40px", "90px", "190px"])}
        onClick={onClick}
      />
    </Box>
  )
}