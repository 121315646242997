import {Box} from "@chakra-ui/react";
import React, {useState} from "react";
import {SliderButtons} from "./SliderButtons";
import {themeColors} from "../config/theme/colors";
import {Splide, SplideSlide, SplideTrack} from "@splidejs/react-splide";
import {spacings} from "../config/theme/sizes";

export const defaultSplideOptions = {
  perMove: 1,
  flickMaxPages: 1,
  flickPower: 1,
}

export const Slider = (props) => {
  const {
    items,
    options,
    getNewNextIndex = (index) => index,
    getNewPrevIndex = (index) => index,
    CardComponent
  } = props

  const [selectedIndex, setSelectedIndex] = useState(-1)

  const handleNextClick = (splide) => {
    const index = getNewNextIndex(splide.index);
    setSelectedIndex(index);
  };

  const handlePrevClick = (splide) => {
    const index = getNewPrevIndex(splide.index);
    setSelectedIndex(index);
  };

  return (
    <Box
      w={'100%'}
      sx={{
        '.splide': {
          marginInline: spacings.section.marginInlineOverflow
        },
        '.splide__arrow': {
          background: themeColors.white,
          display: 'flex',
          alignItems: 'center',
          opacity: 1,
        },
        '.splide__arrow--prev': {
          justifyContent: 'flex-start'
        },
        '.splide__arrow--next': {
          justifyContent: 'flex-end'
        },
        '.splide__arrow--prev svg': {
          height: 'auto',
          transform: 'scaleX(1)'
        }
      }}
    >
      <Splide
        options={{
          ...options,
          ...defaultSplideOptions
        }}
        onMounted={(splide) => setSelectedIndex(splide.index)}
        onArrowsMounted={(splide, prev, next) => {
          prev.onclick = (e) => handlePrevClick(splide)
          next.onclick = (e) => handleNextClick(splide)
        }}
        onMoved={(splide) => setSelectedIndex(splide.index)}
        hasTrack={false}
      >
        <SplideTrack>
          {
            items.map((card, index) => {
              return (
                <SplideSlide key={index}>
                  <CardComponent
                    isActive={selectedIndex === index}
                    card={card}
                    onClick={() => setSelectedIndex(index)}
                  />
                </SplideSlide>
              )
            })
          }
        </SplideTrack>
        <SliderButtons/>
      </Splide>
    </Box>
  )
}