import {Image} from "@chakra-ui/react";
import TokenImg from "../../../assets/images/roadmapToken.png";
import {RoadmapCard} from "../../../../components/cards";
import DevicesImg from "../../../assets/images/roadmapDevices.png";
import NutritionImg from "../../../assets/images/roadmapNutrition.png";
import {s} from "../../../../helper/common";
import React from "react";

export const getCurrentStep = (stepNum) => {
  return {
    isFirst: stepNum === 1,
    isSecond: stepNum === 2,
    isThird: stepNum === 3,
    isFourth: stepNum === 4
  }
}

export const getGradationStep = (stepNum) => {
  return {
    moreFirst: stepNum > 1,
    moreSecond: stepNum > 2,
    moreThird: stepNum > 3,
    moreFourth: stepNum > 4
  }
}

const imgCommonConfig = {
  objectFit: 'contain',
  w: s(["100px", "230px", "230px", "267px", "295px"]),
  h: s(["120px", "275px", "275px", "320px", "350px"]),
  radius: s(["16px"]),
}

export const stepCards = {
  1: {
    img: null,
    card: null,
  },
  2: {
    img: (
      <Image
        position={'absolute'}
        zindex={-1}
        src={TokenImg}
        {...imgCommonConfig}
        top={s(["-65px", "-160px", "-170px", "-175px", "-200px"])}
        right={s(["-30px", "-130px", "-110px", "-160px", "-160px"])}
        rotation={s(["-5deg"])}
      />
    ),
    card: (
      <RoadmapCard
        label={"DONE"}
        // title={"cryptofood® token launch"}
        title={"cryptofood token launch"}
        description={"We have successfully launched the Cryptofood® digital currency, offering early adopters the opportunity to join our community and invest in the future of functional food."}
      />
    ),
    pos: {
      position: "absolute",
      left: "0"
    }
  },
  3: {
    img: (
      <Image
        position={'absolute'}
        zindex={-1}
        src={DevicesImg}
        {...imgCommonConfig}
        top={s(["-80px", "-160px", "-170px", "-175px", "-200px"])}
        left={s(["-17px", "-130px", "-150px", "-170px", "-190px"])}
        rotation={s(["8deg"])}
      />
    ),
    card: (
      <RoadmapCard
        label={"in progress"}
        title={"innovate heating devices "}
        description={"We are currently in the production phase of the pilot prototype of our innovative Cryptofood® device. It will soon be ready for use, revolutionizing the way we prepare meals."}
      />
    ),
    pos: {
      margin: "0 auto",
      position: "absolute",
      left: "0",
      right: "0"
    }
  },
  4: {
    img: (
      <Image
        position={'absolute'}
        zindex={-1}
        src={NutritionImg}
        {...imgCommonConfig}
        top={s(["-80px", "-160px", "-170px", "-175px", "-200px"])}
        left={s(["-17px", "-130px", "-150px", "-170px", "-190px"])}
        rotation={s(["8deg"])}
      />
    ),
    card: (
      <RoadmapCard
        label={"to do"}
        title={"functional food under our own brand"}
        description={"As we pave the way for a healthier future, we have plans to unleash our own line of functional food products under the Cryptofood® brand. Meanwhile, our devices can be used with other functional or baby food brands."}
      />
    ),
    pos: {
      position: "absolute",
      right: "0"
    }
  }
}
