import React from 'react';
import {Flex, Heading, Text} from "@chakra-ui/react";
import {componentsSizeTheme} from "../config/theme/sizes";
import {DotIcon} from "./icons";
import {s} from "../helper/common";


export const Headline1 = (props) => <Heading {...componentsSizeTheme.Headline1} {...props} />
export const Headline2 = (props) => <Heading {...componentsSizeTheme.Headline2} {...props} />
export const Subhead1 = (props) => <Heading {...componentsSizeTheme.Subhead1} {...props} />
export const Subhead2 = (props) => <Heading {...componentsSizeTheme.Subhead2} {...props} />
export const Caption1 = (props) => <Heading {...componentsSizeTheme.Caption1} {...props} />
export const Body1 = (props) => <Text {...componentsSizeTheme.Body1} {...props} />
export const Body2 = (props) => <Text {...componentsSizeTheme.Body2} {...props} />
export const Label1 = (props) => <Text {...componentsSizeTheme.Label1} {...props} />

export const RSymbol = ({fontWeight = 375}) => <span style={{fontWeight}}>®</span>

export const TextWithDot = (props) => {
  const {
    text,
    color,
    ...restProps
  } = props
  return (
    <Flex
      gap={s(['6px', '8px', '8px', '12px', '12px'])}
      color={color}
      {...restProps}
    >
      <DotIcon
        width={s(['8px', '12px'])}
        height={s(['8px', '12px'])}
        m={'3px 0'}
      />
      <Body2
        textTransform={'uppercase'}
        children={text}
        color={color}
      />
    </Flex>
  )
}

export const GradientText = (props) => {
  const {
    text,
    margin
  } = props
  return (
    <span
      style={{
        background: "-webkit-linear-gradient(0deg, #009A6C 30%, #00477A 90%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        margin: margin
      }}
    >
       {text}
    </span>
  )
}