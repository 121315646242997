import {Icon} from "@chakra-ui/react";

export const TelegramIcon = (props) => (
  <Icon
    color={"currentColor"}
    {...props}
    viewBox="0 0 24 24"
  >
    <path
      d="M0.420841 11.0798L5.93856 13.1436L8.08952 20.0854C8.18304 20.5545 8.74416 20.6483 9.11824 20.3668L12.2044 17.834C12.485 17.5526 12.9526 17.5526 13.3267 17.834L18.8443 21.8678C19.2184 22.1492 19.7796 21.9616 19.8731 21.4925L23.988 1.79282C24.0815 1.32378 23.6139 0.854742 23.1463 1.04236L0.420841 9.86033C-0.14028 10.0479 -0.14028 10.8922 0.420841 11.0798ZM7.80897 12.1117L18.6573 5.45134C18.8443 5.35753 19.0314 5.63896 18.8443 5.73277L9.95992 14.0817C9.67936 14.3631 9.39879 14.7384 9.39879 15.2074L9.11824 17.4588C9.11824 17.7402 8.65064 17.834 8.55712 17.4588L7.43486 13.3312C7.1543 12.8622 7.34136 12.2993 7.80897 12.1117Z"
      fill="currentColor"
    />
  </Icon>
)