import {Box, HStack, Modal, ModalBody, ModalContent, ModalHeader, VStack,} from "@chakra-ui/react";
import React from "react";
import {CloseIcon, ArrowRightIcon, LinkButtonSmall, LogoIcon} from "../../../components";
import {NavMenu} from "../NavMenu/NavMenu";
import {networksLinks} from "../../../config/links";


export const MobileMenu = (props) => {
  const {
    isOpen,
    onClose,
  } = props

  return (
    <Modal
      onClose={onClose}
      size={'full'}
      isOpen={isOpen}
    >
      <ModalContent bg={'rgba(255,255,255,.5)'} backdropFilter={'blur(10px)'}>
        <ModalHeader p={'8px 8px 0'} h={'40px'}>
          <HStack
            align="center"
            justify={'space-between'}
          >
            <Box
              as={'button'}
              onClick={onClose}
              flexShrink="0"
            >
              <CloseIcon
                w={"16px"}
                h={"16px"}
                color={'grey.900'}
              />
            </Box>
            <LogoIcon />
            <Box w={'24px'}> </Box>
          </HStack>
        </ModalHeader>
        <ModalBody p={'24px 0'}>
          <VStack spacing={'40px'}>
            <NavMenu
              isMobile={true}
              align="center"
              onCloseMenu={onClose}
            />
            <LinkButtonSmall
              rightIcon={<ArrowRightIcon/>}
              href={networksLinks.telegram}
            >
              SIGN UP FOR THE UPDATES
            </LinkButtonSmall>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}