import {Box, Image} from "@chakra-ui/react";
import LunchImg from '../../../../../../assets/images/devices/devicesLunchImgFirstBlock.png'
import SnakImg from '../../../../../../assets/images/devices/devicesSnakImgFirstBlock.png'
import {s} from "../../../../../../../helper/common";
import {transTime} from "../../../../../../../config/theme/consts";

export const ImageBlock = (props) => {
  const lanchSize = s(["175px", "298px", "435px", "584px", "690px"])
  const snakSize = s(["108px", "184px", "270px", "361px", "427px"])

  const transition = `left ${transTime}, right ${transTime}, top ${transTime}, min-width ${transTime}, max-width ${transTime}`
  return (
    <Box
      {...props}
      position={'relative'}
      minW={'1px'}
      maxW={'1px'}
      m={'0 auto'}
    >
      <Image
        src={SnakImg}
        minW={snakSize}
        maxW={snakSize}
        rotation={["-15deg"]}
        position={"absolute"}
        m={'auto'}
        top={s(['10px', "20px", '5px', "5px", '5px'])}
        left={s(['-100px', '-145px', "-220px", "-270px", "-360px"])}
        right={[0]}
        transition={transition}
      />
      <Image
        src={LunchImg}
        minW={lanchSize}
        maxW={lanchSize}
        rotation={["8deg"]}
        position={"absolute"}
        zIndex={1}
        m={'auto'}
        top={s(['25px', "50px", "60px", "60px", "70px"])}
        left={s(['-70px', '-110px', "-170px", "-220px", "-280px"])}
        right={[0]}
        transition={transition}
      />
    </Box>
  )
}