import {extendTheme, theme as base} from "@chakra-ui/react";
import {themeColors} from "./colors";
import {tabsTheme} from "./components/tabs";
import {buttonTheme} from "./components/button";
import {viewSizes} from "./sizes";

export const customTheme = extendTheme({
  breakpoints: {
    base: `${viewSizes.base}px`,
    sm: `${viewSizes.small}px`,
    md: `${viewSizes.mobile}px`,
    lg: `${viewSizes.tablet}px`,
    xl: `${viewSizes.desktop}px`,
    "2xl": `${viewSizes.wide}px`,
  },
  components: {
    Tabs: tabsTheme(),
    Button: buttonTheme(),
  },
  colors: themeColors,
  styles: {
    global:  (props) => {
      return ({
        'html, body, h1, h2, h3, h4, h5, h6, p, span': {
          fontFamily: `"PP Neue Montreal", ${base.fonts?.heading}, sans-serif !important`,
        },
        'body': {
          bg: 'grey.50',
        },
      })
    }
  }
})