import React from "react";
import {ColorCircle} from "../../../../../components";

export const ColorBlock = () => (
  <>
    <ColorCircle
      top={"-20%"}
      right={'-30%'}
      position="absolute"
      bg={"radial-gradient(50% 50% at 50% 50%, rgba(0, 71, 122, 0.84) 0%, rgba(0, 71, 122, 0) 100%)"}
      opacity={'.57'}
    />
    <ColorCircle
      top={'30%'}
      left={"-45%"}
      position="absolute"
      bg={"radial-gradient(50% 50% at 50% 50%, #009A6C 0%, rgba(0, 154, 108, 0) 100%)"}
      opacity={'.4'}
    />
  </>
)