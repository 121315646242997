import React from 'react';
import {Box, Flex, VStack} from "@chakra-ui/react";
import {spacings} from "../../../../config/theme/sizes";
import {ColorBlock} from "./components/ColorBlock";
import {TitleBlock} from "./components/TitleBlock";
import {ImageTokenBlock} from "./components/ImageTokenBlock";
import {DescriptionBlock} from "./components/DescriptionBlock";
import {s} from "../../../../helper/common";
import {SliderBlock} from "./components/SliderBlock/SliderBlock";
import {navigationKeys} from "../../../../config/navigation";
import {networksLinks} from "../../../../config/links";
import {DualWithDotBlock, DualBlock} from "../../../../components";
import {TokenContract} from "./components/TokenContract";


export const TokenSection = (props) => {
  const {
    tokenAnimation
  } = props

  const VStackWrapper = (props) => (
    <VStack
      gap={s(["26px", "40px", "42px", "53px", "47px"])}
      align="left"
      {...props}
    />
  )

  return (
    <Flex
      id={navigationKeys.token.key}
      p={spacings.section.main}
      bg={'grey.900'}
      alignItems={"center"}
      position="relative"
      overflow={'hidden'}
      flexDirection={"column"}
      zIndex={'4'}
    >
      <VStackWrapper
        w={'100%'}
        position={'relative'}
      >
        <Flex
          w={'100%'}
          h={'100%'}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <ColorBlock/>

          <TitleBlock/>

          <VStackWrapper justify={'center'}>
            <ImageTokenBlock tokenAnimation={tokenAnimation}/>
          </VStackWrapper>
        </Flex>
        <DualBlock
          templateColumns={s(['1fr', '92px 1fr', '248px 1fr', '439px 1fr', '546px 1fr'])}
          left={<Box/>}
          right={
            <VStackWrapper>
              <DescriptionBlock/>
              <TokenContract/>
            </VStackWrapper>
          }
        />
      </VStackWrapper>

      <SliderBlock/>

      <DualWithDotBlock
        dotColor={'grey.200'}
        dotText={"what’s next?"}
        mainText={'sign up to be an early bird and get launch notification'}
        mainTextColor={'grey.50'}
        btnText={'let’s go!'}
        mainTextW={s(['100%', "460px", "680px", "795px", "1040px"])}
        dualProps={{
          templateColumns: s(['1fr', '276px 1fr', '310px 1fr', '440px 1fr', '546px 1fr'])
        }}
        btnHref={networksLinks.telegram}
      />
    </Flex>
  );
};
