import {GradientText, Headline2, TextWithDot} from "../../../../../components";
import {Box, VStack} from "@chakra-ui/react";
import React from "react";
import {s} from "../../../../../helper/common";


export const TitleBlock = (props) => {
  const {} = props
  return (
    <VStack
      align="center"
      gap={s(["12px", "16px", "16px", "24px", "32px"])}
    >
      <TextWithDot
        color={"grey.200"}
        text={'investing in the future'}
        justify="center"
      />
      <Headline2
        color={'grey.50'}
        align="center"
      >
        feel the
        <GradientText
          margin={'0 5px'}
          text={"power of CFD,"}
        />
        <br/>
        the cryptofood® token
      </Headline2>
    </VStack>
  )
}