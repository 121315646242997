import {theme as chakraTheme} from "@chakra-ui/react";
import {accentColorSchema} from "../colors";

const { Tabs } = chakraTheme.components

export const tabsTheme = () => ({
	...Tabs,
	defaultProps: {
		...Tabs.defaultProps,
		colorScheme: accentColorSchema,
	},
  variants: {
    ...Tabs.variants,
    line: {
      ...Tabs.variants.line,
      tab: {
        ...Tabs.variants.line.tab,
        _hover: {
          color: 'green.900',
        },
        _active: {
          bg: 'transparent',
        },
        _selected: {
          color: 'green.300',
          borderColor: 'green.300',
          _hover: {
            color: 'green.400',
            borderColor: 'green.400'
          },
        }
      },
      tablist: {
        ...Tabs.variants.line.tablist,
        color: 'grey.500',
        borderColor: 'transparent',
      }
    }
  }
})