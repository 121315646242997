import {Icon} from "@chakra-ui/react";

export const NutritionIcon = (props) => (
  <Icon
    color={"currentColor"}
    {...props}
    viewBox="0 0 80 80"
  >
      <path fillRule="evenodd" clipRule="evenodd"
            d="M60.3962 31.3423C58.5829 31.3423 57.4273 30.3289 57.4273 28.8178C57.4273 26.8623 58.814 26.24 61.1607 25.8311C61.242 25.8166 61.3217 25.8026 61.3998 25.7888C62.7557 25.55 63.614 25.3988 63.614 24.3734C63.614 23.4489 63.0095 22.8267 61.5518 22.8267C59.7918 22.8267 59.1695 23.4489 59.0807 24.7823L57.854 24.7823C57.9429 23.1823 58.9562 21.8134 61.5873 21.8134C63.2584 21.8134 64.734 22.5245 64.734 24.6934L64.734 29.2267C64.734 29.9556 64.8407 30.2934 65.2495 30.2934C65.3207 30.2934 65.3918 30.2934 65.4984 30.2756L65.4984 31.1111C65.3029 31.1467 65.1073 31.1823 64.894 31.1823C64.1295 31.1823 63.6851 30.88 63.614 29.7423L63.5784 29.7423C62.9562 30.7734 61.8718 31.3423 60.3962 31.3423ZM60.5384 30.3289C62.3873 30.3289 63.614 29.3511 63.614 27.7156L63.614 26.0267C63.3473 26.4 62.3873 26.6134 61.3029 26.7911C59.5429 27.1111 58.6362 27.5556 58.6362 28.7111C58.6362 29.76 59.2229 30.3289 60.5384 30.3289Z"
            fill="currentColor"/>
      <path
        d="M45.1552 24.7644C45.1552 28.6222 47.5374 31.3422 50.9863 31.3422C54.0085 31.3422 55.9818 29.3689 56.2841 26.5956L54.933 26.5956C54.7907 28.3556 53.6707 30.2044 50.9863 30.2044C47.9285 30.2044 46.5063 27.6089 46.5063 24.7644C46.5063 21.92 47.9107 19.3067 50.9863 19.3067C53.5818 19.3067 54.6307 21.0311 54.8796 22.4533L56.2307 22.4533C55.9107 20.0711 53.973 18.1689 51.0041 18.1689C47.5196 18.1689 45.1552 20.9244 45.1552 24.7644Z"
        fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M55.5556 44.4445C66.6013 44.4445 75.5556 35.4902 75.5556 24.4445C75.5556 13.3988 66.6013 4.44446 55.5556 4.44446C44.5099 4.44446 35.5556 13.3988 35.5556 24.4445C35.5556 35.4902 44.5099 44.4445 55.5556 44.4445ZM55.5556 43.3333C65.9876 43.3333 74.4445 34.8765 74.4445 24.4445C74.4445 14.0124 65.9876 5.55557 55.5556 5.55557C45.1235 5.55557 36.6667 14.0124 36.6667 24.4445C36.6667 34.8765 45.1235 43.3333 55.5556 43.3333Z"
            fill="currentColor"/>
      <path
        d="M26.6398 53.3334C23.9855 53.3334 22.4371 51.8956 22.4371 49.4782L23.6379 49.4782C23.6379 51.153 24.4594 52.3222 26.5608 52.3222C28.4568 52.3222 29.2152 51.469 29.2152 50.1102C29.2152 48.4512 27.9512 47.9299 26.8294 47.9299L25.6602 47.9299L25.6602 46.9187L26.7662 46.9187C27.9512 46.9187 28.8044 46.2709 28.8044 44.9595C28.8044 43.9799 28.1882 43.0161 26.624 43.0161C24.4278 43.0161 23.9381 44.2643 23.9381 45.4335L22.7373 45.4335C22.7373 43.4743 24.0171 42.005 26.624 42.005C28.4094 42.005 30.0052 43.0793 30.0052 44.9595C30.0052 46.1761 29.4048 46.9029 28.2988 47.3453L28.2988 47.3769C29.7682 47.8351 30.416 48.8146 30.416 50.1102C30.416 52.1642 28.8992 53.3334 26.6398 53.3334Z"
        fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M12.2222 53.128L16.267 53.128C19.4269 53.128 21.2281 50.995 21.2281 47.5032C21.2281 44.0115 19.4743 41.8311 16.3144 41.8311L12.2222 41.8311L12.2222 53.128ZM16.267 52.1168L13.3756 52.1168L13.3756 42.8423L16.2986 42.8423C19.1425 42.8423 20.0273 44.9911 20.0273 47.5032C20.0273 49.9996 19.0951 52.1168 16.267 52.1168Z"
            fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M21.1111 64.4445C30.3159 64.4445 37.7778 56.9825 37.7778 47.7778C37.7778 38.573 30.3159 31.1111 21.1111 31.1111C11.9064 31.1111 4.44446 38.573 4.44446 47.7778C4.44446 56.9825 11.9064 64.4445 21.1111 64.4445ZM21.1111 63.3334C29.7022 63.3334 36.6667 56.3689 36.6667 47.7778C36.6667 39.1867 29.7022 32.2222 21.1111 32.2222C12.52 32.2222 5.55557 39.1867 5.55557 47.7778C5.55557 56.3689 12.52 63.3334 21.1111 63.3334Z"
            fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M60.1643 60.7541L60.1643 59.9186L60.8431 59.9186L60.8431 64.9104C60.8431 66.4246 59.9033 67.1556 58.4413 67.1556C57.0001 67.1556 56.2378 66.4559 56.1542 65.5265L56.8644 65.5265C56.9688 66.1009 57.4178 66.5604 58.4517 66.5604C59.5273 66.5604 60.1643 66.0904 60.1643 65.0043L60.1643 64.0123L60.1435 64.0123C59.8302 64.5657 59.2454 64.9939 58.3473 64.9939C57.0732 64.9939 56.008 64.1585 56.008 62.3832C56.008 60.6287 57.0732 59.7933 58.3473 59.7933C59.2454 59.7933 59.8302 60.2215 60.1435 60.7541L60.1643 60.7541ZM58.4413 64.3987C59.4229 64.3987 60.1643 63.7512 60.1643 62.3832C60.1643 61.036 59.4229 60.3886 58.4413 60.3886C57.3447 60.3886 56.7182 61.1822 56.7182 62.3832C56.7182 63.605 57.3447 64.3987 58.4413 64.3987Z"
            fill="currentColor"/>
      <path
        d="M47.7778 65.2445L48.5401 65.2445L48.5401 58.8534L48.561 58.8534L51.0151 65.2445L51.7043 65.2445L54.1689 58.8534L54.1898 58.8534L54.1898 65.2445L54.9521 65.2445L54.9521 57.7778L53.8974 57.7778L51.3702 64.2211L51.3493 64.2211L48.8325 57.7778L47.7778 57.7778L47.7778 65.2445Z"
        fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M66.6667 61.6667C66.6667 68.7237 60.9459 74.4445 53.8889 74.4445C46.8319 74.4445 41.1111 68.7237 41.1111 61.6667C41.1111 54.6097 46.8319 48.8889 53.8889 48.8889C60.9459 48.8889 66.6667 54.6097 66.6667 61.6667ZM65.5556 61.6667C65.5556 68.11 60.3322 73.3334 53.8889 73.3334C47.4456 73.3334 42.2222 68.11 42.2222 61.6667C42.2222 55.2234 47.4456 50 53.8889 50C60.3322 50 65.5556 55.2234 65.5556 61.6667Z"
            fill="currentColor"/>
  </Icon>
)