import {Flex} from "@chakra-ui/react";
import {s} from "../helper/common";
import {LabelItem} from './LabelItem'
import {LabelLine} from "./LabelLine";


export const DescriptionBlock = (props) => {
  const {
    title,
    description,
    textAlign,
    containerAlignItems,
    wrapperDirection='column',
    direction,
    gap = s(['12px', '24px', '24px', '32px', '40px']),
    textW,
    lineW,
    extraMainLabel,
    ...restProps
  } = props
  return (
    <Flex
      direction={wrapperDirection}
      gap={gap}
      width={'max-content'}
      alignItems={containerAlignItems}
      {...restProps}
    >
      <LabelItem
        title={title}
        description={description}
        textAlign={textAlign}
        w={textW}
        extraMainLabel={extraMainLabel}
      />
      <LabelLine w={lineW} direction={direction}/>
    </Flex>
  )
}