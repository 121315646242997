
export const themeColors = {
  grey: {
    '900': '#121212',
    '500': '#6E6E6E',
    '400': '#838383',
    '300': '#8B8B8B99',
    '200': '#B5C4BC',
    '50': '#F7F7F7',
    '60%': 'rgba(52,52,52,0.6)',
    '80%': 'rgba(139,139,139,0.8)',
  },
  green: {
    '900': '#002C1F',
    '700': '#547363',
    '600': '#709984',
    '400': '#007652',
    '300': '#009A6C',
    '200': '#85D4AB',
    '150': '#B5C4BC',
    '100': '#BFD6CA',
    '50': '#C5FBDF',
    '80%': 'rgba(0,44,31,0.8)',
    '60%': 'rgba(0,44,31,0.6)',
    '50%': 'rgba(0,154,108,0.5)',
  },
  white: '#FFFFFF',
  whiteAlpha: {
    '800': 'rgba(255,255,255,0.8)',
    '600': 'rgba(255,255,255,0.6)',
    '400': 'rgba(255,255,255,0.4)',
  },
  gradient: {
    color1: '#009A6C',
    color2: '#00477A'
  }
}

export const accentColorSchema = "green"
export const accentColor = `${accentColorSchema}.300`
