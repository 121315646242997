import {Box} from "@chakra-ui/react";
import {s} from "../helper/common";
import {SliderArrowRightIcon} from "./icons";
import React from "react";


const btnSize = {
  h: s(["32px", "40px", '40px', "48px", "56px"]).map(item => `${item}!important`),
  w: s(["32px", "40px", '40px', "48px", "56px"]).map(item => `${item}!important`),
  color: 'grey.900',
  flexShrink:0
}

export const SliderButtons = () => {

  const topPos = s([
    "95px", "95px", "120px", "160px", "160px"
  ])
  const inlinePos = s([
    "32px", "32px", "40px", "48px", "48px"
  ])

  const commonSettings = {
    as: 'button',
    h: s(["auto", "56px", "56px", "64px", "64px"]),
    w: s(["auto", "56px", "56px", "64px", "64px"]),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center!important',
    position: 'abdolute',
    boxShadow: '0px 8px 15px 8px rgba(0, 0, 0, 0.09)',
    _disabled: {opacity: 0},
  }


  return (
    <>
      <div className="splide__arrows">
        <Box
          {...commonSettings}
          className="splide__arrow splide__arrow--prev"
          top={topPos}
          left={inlinePos}
        >
          <SliderArrowRightIcon {...btnSize}/>
        </Box>

        <Box
          {...commonSettings}
          justifyContent={'center!important'}
          top={topPos}
          right={inlinePos}
          className="splide__arrow splide__arrow--next"
        >
          <SliderArrowRightIcon {...btnSize}/>
        </Box>
      </div>
    </>
  )
}