import {Grid, GridItem, useBreakpointValue} from "@chakra-ui/react";
import {spacings} from "../../../../../../config/theme/sizes";
import {InfoBlock} from "./components/InfoBlock";
import {ImageBlock} from "./components/ImageBlock";
import {s} from "../../../../../../helper/common";
import {AdvantageSlider} from "./components/AdvantageSlider";


export const FirstSolutionsBlock = () => {
  const isSliderHidden = useBreakpointValue(
    {base: false, lg: true},
    {fallback: 'lg'},
  )
  return (
    <Grid
      pt={spacings.section.mainVertical}
      pl={spacings.section.paddingInlineOverflow}
      pr={spacings.section.paddingInlineOverflow}
      templateColumns={s(['1fr', '276px 1fr', '434px 1fr', '616px 1fr', '780px 1fr'])}
      gap={s(["24px 0", "110px 0", "64px 0", "80px 0", "96px 0"])}
      templateRows={
        s(['212px max-content max-content', '1fr max-content', "1fr"])
      }
    >
      <ImageBlock/>
      <InfoBlock/>
      <GridItem
        hidden={isSliderHidden}
        colSpan={s([1, 2])}
      >
        <AdvantageSlider
          hidden={isSliderHidden}
        />
      </GridItem>
    </Grid>
  )
}