import {Box} from "@chakra-ui/react";
import React, {createRef, useRef, useState} from "react";
import {s} from "../../../../../../helper/common";

import {FeatureCard} from "../../../../../../components/cards";
import {sliderCardsData} from "./data";
import {Splide, SplideSlide, SplideTrack} from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import {defaultSplideOptions, GreenSliderArrowLeftIcon, GreenSliderArrowRightIcon} from "../../../../../../components";
import {sliderOptions, sliderWrapperStyles} from "./sliderHelper";


export const SliderBlock = (props) => {

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const mainWrapperRef = useRef(null)
  const itemsWithRef = sliderCardsData.map(item => ({...item, ref: createRef()}))

  const iconSize = {
    w: s(["132px!important", "132px!important", "132px!important", "160px!important", "160px!important"]),
    h: s(["48px!important", "48px!important", "48px!important", "58px!important", "58px!important"]),
  }
  return (
    <>
      <Box
        w={'100%'}
        m={s(["40px 0 48px", "60px 0 60px", "60px 0 60px", "100px 0 100px", "124px 0 130px"])}
        position={'relative'}
        ref={mainWrapperRef}
        sx={sliderWrapperStyles}
      >
        <Splide
          options={{
            ...sliderOptions,
            ...defaultSplideOptions
          }}
          onMounted={(splide) => {
            setSelectedIndex(splide.index)
          }}
          onArrowsMounted={(splide, prev, next) => {
            prev.onclick = (e) => setSelectedIndex(splide.index)
            next.onclick = (e) => setSelectedIndex(splide.index)
          }}
          onMoved={(splide) => {
            setSelectedIndex(splide.index)
          }}
          hasTrack={false}
        >
          <SplideTrack>
            {
              itemsWithRef.map(({ref, ...card}, index, ...rest) => {
                return (
                  <SplideSlide key={index}>
                    <FeatureCard
                      isActive={index === selectedIndex}
                      title={card.title}
                      description={card.description}
                    />
                  </SplideSlide>
                )
              })
            }
          </SplideTrack>

          <div className="splide__arrows">
            <button className="splide__arrow splide__arrow--prev">
              <GreenSliderArrowLeftIcon {...iconSize}/>
            </button>
            <button className="splide__arrow splide__arrow--next">
              <GreenSliderArrowRightIcon {...iconSize}/>
            </button>
          </div>
        </Splide>
      </Box>
    </>
  )
}