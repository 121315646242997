import {devicesEnum} from "../../enum";
import lunch1Img from "../../../../../assets/images/devices/steps/lunch/lunch1Img.png";
import lunch2Img from "../../../../../assets/images/devices/steps/lunch/lunch2Img.png";
import lunch3Img from "../../../../../assets/images/devices/steps/lunch/lunch3Img.png";
import lunch4Img from "../../../../../assets/images/devices/steps/lunch/lunch4Img.png";
import snack1Img from "../../../../../assets/images/devices/steps/snack/snack1Img.png";
import snack2Img from "../../../../../assets/images/devices/steps/snack/snack2Img.png";
import snack3Img from "../../../../../assets/images/devices/steps/snack/snack3Img.png";
import {s} from "../../../../../../helper/common";


export const dataSteps = {
    [devicesEnum.lunch]: {
      title: "how to use the lunchboxer?",
      steps: [
        {
          id: '1',
          img: lunch1Img,
          label: "Open the lid at the bottom of the device"
        },
        // {
        //   id: '2',
        //   img: lunch2Img,
        //   label:
        // },
        {
          id: '2',
          img: lunch3Img,
          label: "Place the food packet inside"
        },
        {
          id: '3',
          img: lunch4Img,
          label: "Press the button and wait for the food to heat up"
        },
      ]
    },
    [devicesEnum.snack]: {
      title: "how to use the snacksprinter?",
      steps: [
        {
          id: '1',
          img: snack1Img,
          label: "Hold the device",
          alignSelf: s(['flex-start', "center", 'flex-end']),
          h: s(["fit-content", "center", "100%"])
        },
        {
          id: '2',
          img: snack2Img,
          label: "Place the food pouch inside"
        },
        {
          id: '3',
          img: snack3Img,
          label: "Press the button and wait for the food to heat up. You can start eating!"
        },
      ]
    },
  }