import {Box, HStack} from "@chakra-ui/react";
import {ArrowRightIcon, DualBlock, GradientButton, Headline2, TextWithDot} from "./index";
import {s} from "../helper/common";
import {themeColors} from "../config/theme/colors";
import {componentsSizeTheme} from "../config/theme/sizes";


export const DualWithDotBlock = (props) => {
  const {
    dotColor,
    dotText,
    p,

    mainTextColor,
    mainText,
    mainTextW,


    btnText = "TODO!",
    btnIcon,
    btnColor = "white",
    btnBgColor = themeColors.grey["900"],
    btnHref,

    dualProps
  } = props

  return (
    <DualBlock
      p={p}
      templateRows={s(['52px 1fr', '1fr'])}
      {...dualProps}
      left={
        <TextWithDot
          text={dotText}
          color={dotColor}
          h={s(["52px"])}
          // w={s(['100%', "260px", "220px", "350px", "460px"])}
          w={s(['100%'])}
          alignItems="flex-end"
          flexShrink={0}
        />
      }
      right={
        <Box
          color={mainTextColor}
          w={mainTextW}
          flexShrink={0}
        >
          <Headline2 color={mainTextColor}>{mainText}</Headline2>
          <HStack
            height={componentsSizeTheme.Headline2.lineHeight}
          >
            <ArrowRightIcon
              w={s(["20px", "40px", "40px", "56px", "64px"])}
              h={s(["20px", "40px", "40px", "56px", "64px"])}
            />
            <GradientButton
              color={btnColor}
              bgColor={btnBgColor}
              leftIcon={btnIcon}
              as={'a'}
              target={"_blank"}
              href={btnHref}
            >
              {btnText}
            </GradientButton>
          </HStack>
        </Box>
      }

    />
  )
}
