import {viewSizes} from "../../../../../config/theme/sizes";

export const sliderOptions = {
  start: 0,
  // direction: 'rtl',
  perPage: 4,
  autoWidth: true,
  gap: '24px',
  padding: {
    left: '24px',
    right: '24px',
  },
  pagination: false,
  breakpoints: {
    [viewSizes.wide]: {
      gap: "40px",
      padding: {
        left: '24px',
        right: '24px',
      }
    },
    [viewSizes.desktop]: {
      gap: "40px",
      padding: {
        left: '16px',
        right: '16px',
      }
    },
    [viewSizes.tablet]: {
      arrows: false,
      gap: '24px',
      padding: {
        left: '16px',
        right: '16px',
      }
    },
    [viewSizes.mobile]: {
      perPage: 1,
      gap: '8px',
      padding: {
        left: '8px',
        right: '8px',
      }
    },
    [viewSizes.small]: {
      gap: '8px',
      padding: {
        left: '8px',
        right: '8px',
      }
    },
  }
}