import { viewSizes } from "../../../../../../config/theme/sizes";

export const sliderOptions = {
  focus: 0,
  start: 0,
  autoWidth: true,
  updateOnMove: true,
  gap: '32px',

  padding: {
    left: '24px',
    right: '24px',
  },
  pagination: false,
  breakpoints: {
    [viewSizes.wide]: {
      gap: "32px",
      padding: {
        left: '570px',
        right: '24px',
      }
    },
    [viewSizes.desktop]: {
      gap: "24px",
      perPage: 3,
      padding: {
        left: '454px',
        right: '16px',
      }
    },
    [viewSizes.tablet]: {
      gap: '24px',
      padding: {
        left: '322px',
        right: '16px',
      }
    },
    [viewSizes.mobile]: {
      focus: 1,
      start: 1,
      arrows: true,
      gap: '24px',
      padding: {
        left: '276px',
        right: '8px',
      }
    },
    [767]: {
      focus: 'center',
      start: 0,
      arrows: false,
      gap: '8px',
      padding: {
        left: '8px',
        right: '8px',
      }
    },
  }
}