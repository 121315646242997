import {Box, useBreakpointValue, VStack} from "@chakra-ui/react";
import {spacings} from "../../../../../../config/theme/sizes";
import React from "react";
import {DualBlock, Headline2, TextWithDot} from "../../../../../../components";
import {s, scrollToAnchor} from "../../../../../../helper/common";
import {LunchArm} from "./components/LunchArm";
import {SnakArm} from "./components/SnakArm";
import {devicesEnum} from "../../enum";
import {deviceSectionStartAnchorId} from "../../data";

export const ChooseBlock = (props) => {
  const {
    setSelectedDeviceKey,
  } = props
  const isSm = useBreakpointValue(
    {base: true, md: false},
    { fallback: 'md' },
  )

  const clickToLink = (deviceKey) => {
    setSelectedDeviceKey(deviceKey)
    scrollToAnchor(`#${deviceSectionStartAnchorId}`)
  }

  return (
    <Box
      p={spacings.section.main}
      overflow={'hidden'}
    >
      <DualBlock
        left={<Box hidden={isSm}/>}
        right={
          <VStack
            align="left"
          >
            <TextWithDot
              text={'meet cryptofood® devices '}
              color={'green.700'}
            />
            <Headline2>
              {'choose one that fits'}
              <br/>
              {'you well'}
            </Headline2>
          </VStack>
        }
      />
      <Box
        position={'relative'}
        h={s(["520px", "606px", "632px", "744px", "756px"])}
        w={spacings.section.marginInlineOverflow.map(inlineMargin => `calc(100% + ${Math.abs(parseInt(inlineMargin) * 2)}px)`)}
        marginInline={spacings.section.marginInlineOverflow}
      >
        <LunchArm onClick={() => clickToLink(devicesEnum.lunch)}/>
        <SnakArm onClick={() => clickToLink(devicesEnum.snack)}/>
      </Box>
    </Box>
  )
}