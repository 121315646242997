import sliderImg1 from "../../../../../assets/images/usercards/usercard1.jpg";
import sliderImg2 from "../../../../../assets/images/usercards/usercard2.jpg";
import sliderImg3 from "../../../../../assets/images/usercards/usercard3.jpg";
import sliderImg4 from "../../../../../assets/images/usercards/usercard4.jpg";


export const sliderData = [
  {
    id: "1",
    img: sliderImg1,
    label: "short on time",
    description: "If you have a busy schedule, always on the go, or work in a place without kitchen facilities, Cryptofood is there for you. Grab a quick bite even during a five-minute break or enjoy a snack in a meeting room"
  },
  {
    id: "2",
    img: sliderImg2,
    label: "on the road or travelling",
    description: "Don't risk eating at unfamiliar places or compromising your digestive system with fast food or unheated meals in hotels. Even during long trips or flights, Cryptofood has got you covered. Its compact size allows it to be carried onto the plane"
  },
  {
    id: "3",
    img: sliderImg3,
    label: "on specific diets",
    description: "For health-conscious individuals following specific diet plans, we understand the importance of timing and meal choices. With Cryptofood, you can maintain any schedule or dietary requirement without having to rely on kitchen facilities or restaurants. We support your healthy lifestyle."
  },
  {
    id: "4",
    img: sliderImg4,
    label: "for athletes and sport enthusiasts",
    description: "Stay fueled during your intense workouts and long runs with Cryptofood. Our pocket-size device is the perfect solution for athletes who need reliable and portable hot meals. Whether it's a marathon or a strenuous training session, you can enjoy warm, nutritious food on the go. With Cryptofood, get the fuel you need, when you need it, without compromising on taste or performance."
  }
]