import {NewsCard} from "../../../../../components/cards";
import React from "react";
import {sliderOptions} from "./sliderHelper";
import {itemsData} from "./data";
import {Slider} from "../../../../../components";

export const SliderBlock = () => {

  return (
    <Slider
      options={sliderOptions}
      items={itemsData}
      CardComponent={({card}) => (
        <NewsCard
          textAlign={'left'}
          direction={'ltr'}
          imgSrc={card.img}
          date={card.date}
          description={card.description}
          cursor={'pointer'}
          onClick={() => {
            window.open(card.link, "_blank")
          }}
        />
      )}
    />
  )
}