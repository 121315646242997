import {Box, Flex} from "@chakra-ui/react";
import {s} from "../helper/common";


export const LabelLine = (props) => {
  const {
    w = '100px',
    direction
  } = props
  const circleSize = s(['4px', '6px'])
  return (
    <Flex
      gap={s(['4px', '8px'])}
      alignItems={'center'}
      direction={direction}
      width={'max-content'}
    >
      <Box bg={'green.900'} borderRadius={'100%'} w={circleSize} h={circleSize}/>
      <Box borderBottom={'1px solid'} borderColor={'green.900'} w={w}/>
    </Flex>
  )
}