import { useState, useEffect } from "react";

export const useIntersection = (refOne, refTwo) => {
  const [isOverlapping, setIsOverlapping] = useState(false);

  const checkIfHeaderIsOverlapping = () => {
    if (refOne.current && refTwo.current) {
      const a = refOne.current.getBoundingClientRect();
      const b = refTwo.current.getBoundingClientRect();

      if (a.top <= b.top + b.height && a.top + a.height > b.top) {
        setIsOverlapping(true);
      } else {
        setIsOverlapping(false);
      }
    }
  };

  useEffect(() => {
    const watchScroll = () => {
      window.addEventListener("scroll", checkIfHeaderIsOverlapping);
    };
    watchScroll();

    return () => {
      window.removeEventListener("scroll", checkIfHeaderIsOverlapping);
    };
  });

  return [isOverlapping];
};
