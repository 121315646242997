import {Icon} from "@chakra-ui/react";

export const FoodIcon = (props) => (
  <Icon
    color={"currentColor"}
    {...props}
    viewBox="0 0 64 64"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M37.768 35.1413C37.5528 31.1613 34.2574 28.0002 30.2236 28.0002C26.0508 28.0002 22.668 31.383 22.668 35.5558C22.668 39.7286 26.0508 43.1113 30.2236 43.1113C30.3711 43.1113 30.5176 43.1071 30.663 43.0988C32.4278 44.1446 34.6098 44.4724 36.7261 43.8254C40.7166 42.6054 42.9625 38.3815 41.7425 34.391L41.6125 33.966L37.768 35.1413ZM30.2236 28.8891C26.5417 28.8891 23.5569 31.8739 23.5569 35.5558C23.5569 38.9638 26.1141 41.7745 29.4144 42.1738C28.4498 41.2924 27.7013 40.1491 27.2916 38.809L27.1617 38.384L36.8887 35.4102C36.8112 31.7955 33.8568 28.8891 30.2236 28.8891ZM36.4662 42.9754C33.088 44.0082 29.5202 42.2259 28.2856 38.9699L41.0084 35.0801C41.8056 38.4699 39.8444 41.9426 36.4662 42.9754Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M30.9041 3.11133C29.7834 3.11133 28.838 3.94585 28.699 5.05792L28.0535 10.2224H17.7168C16.5204 10.2224 15.5387 11.1697 15.496 12.3653L13.8452 58.5876C13.8003 59.8452 14.8076 60.8891 16.066 60.8891H47.9368C49.1951 60.8891 50.2025 59.8452 50.1576 58.5876L48.5068 12.3653C48.4641 11.1697 47.4824 10.2224 46.286 10.2224H35.9493L35.3037 5.05792C35.1647 3.94585 34.2194 3.11133 33.0987 3.11133H30.9041ZM35.0535 10.2224L34.4217 5.16817C34.3383 4.50093 33.7711 4.00022 33.0987 4.00022H30.9041C30.2317 4.00022 29.6645 4.50093 29.5811 5.16817L28.9493 10.2224H35.0535ZM17.7168 11.1113C16.9989 11.1113 16.4099 11.6797 16.3843 12.3971L14.7335 58.6193C14.7066 59.3739 15.311 60.0002 16.066 60.0002H17.8346C17.5424 59.6113 17.3751 59.1239 17.3913 58.5985L18.7041 15.9319C18.7411 14.7317 19.7246 13.778 20.9253 13.778H43.0775C44.2781 13.778 45.2617 14.7318 45.2986 15.9319L46.6115 58.5985C46.6276 59.1239 46.4603 59.6113 46.1682 60.0002H47.9368C48.6918 60.0002 49.2962 59.3739 49.2692 58.6193L47.6184 12.3971C47.5928 11.6797 47.0038 11.1113 46.286 11.1113H17.7168ZM44.3903 60.0002H19.6125C18.86 60.0002 18.2566 59.378 18.2798 58.6259L19.5926 15.9592C19.6147 15.2391 20.2049 14.6669 20.9253 14.6669H43.0775C43.7979 14.6669 44.388 15.2391 44.4102 15.9592L45.723 58.6259C45.7461 59.378 45.1427 60.0002 44.3903 60.0002Z" fill="currentColor"/>
  </Icon>
)