import {Box, Grid, Image, VStack} from "@chakra-ui/react";
import {spacings} from "../../../../../../config/theme/sizes";
import {s} from "../../../../../../helper/common";
import {Body1, Headline1, Subhead2, TextWithDot} from "../../../../../../components";
import React from "react";
import {animated} from "@react-spring/web";


const StepItem = (props) => {
  const {
    id,
    img,
    label,
    alignSelf,
    h
  } = props

  return (
    <Grid
      align="end"
      templateColumns={s(["1fr", "1fr 322px 1fr", "1fr 390px 1fr", "1fr 528px 1fr", "1fr 624px 1fr"])}
      templateRows={s(["1fr"])}
      alignItems={['center']}
      textAlign={'left'}
      justifyItems={s(["center", "center"])}
      w={'100%'}
      gap={s(["30px", "8px", "0"])}
      h={"100%"}
      mt={'20px'}
    >
      <Box display={s(['none', 'block'])}/>
      <Box
        position={'relative'}
        w={s(["80vw", '100%'])}
        h={s(['100%'])}
      >
        <Image
          bottom={0}
          position={'absolute'}
          src={img}
          w={s(['100%'])}
          height={s(['100%'])}
          // alignSelf={alignSelf || 'flex-end'}
          objectFit={'contain'}
        />
      </Box>
      <VStack
        gap={s(["8px", "12px", "12px", "16px", "24px"])}
        mb={s(["0", "40px", "40px", "56px", "64px"])}
        w={s(["100%", "230px", "310px", "352px", "468px"])}
        p={s(["8px 12px", '0'])}
        borderRadius={s(['16px', '0'])}
        bg={s(["whiteAlpha.800", 'transparent'])}
        align="left"
        alignSelf={["end"]}
        justifySelf={'left'}
      >
        <Body1>Step {id}</Body1>
        <Subhead2>{label}</Subhead2>
      </VStack>
    </Grid>
  )
}

export const HowToUseBlock = (props) => {
  const {
    stepsOpacities = [],
    elementsData,
  } = props

  const corrData = elementsData
  const mobileTitleDisplay = {base: 'none', md: 'flex', lg: 'none'}
  const desktopTitleDisplay = {base: 'flex',  md: 'none', lg: 'flex'}

  return (
    <>
      <VStack
        id={'howToUseBlock'}
        pt={spacings.section.mainVertical}
        gap={s(["12px", "16px", "16px", "24px", "32px"])}
        w={'100%'}
        mb={s(["8px", "12px", "12px", "20px", "24px"])}
        display={desktopTitleDisplay}
        align={'center'}
      >
        <TextWithDot
          text={corrData.title}
          color={'green.700'}
        />
        <Headline1 align="center">
          just three simple <br/> actions to start <br/> eating
        </Headline1>
      </VStack>
      <VStack
        paddingInline={spacings.section.paddingInlineOverflow}
        gap={s(["12px", "16px", "16px", "24px", "32px"])}
        w={'100%'}
        position={'sticky'}
        top={s(['7dvh'])}
        height={s(["90dvh", "90dvh", "85dvh"])}
      >
        <TextWithDot
          text={corrData.title}
          color={'green.700'}
          display={mobileTitleDisplay}
        />
        <VStack
          gap={s(["8px", "12px", "12px", "20px", "24px"])}
          w={'100%'}
          h={'100%'}
        >
          <Headline1
            align="center"
            display={mobileTitleDisplay}
          >
            just three simple <br/> actions to start <br/> eating
          </Headline1>

          <Box
            position="relative"
            w={'100%'}
            h={'100%'}
          >
            {
              corrData.steps.map((step, i) => {
                const opacity = stepsOpacities[i]
                return (
                  <React.Fragment key={step.id}>
                    <animated.div style={{
                      opacity: opacity.to((value) => value),
                      top: '0',
                      position: opacity.to(value => value >= 1 ? 'static' : 'absolute' ),
                      transition: 'opacity .4s',
                      width: '100%',
                      height: '100%',
                      zIndex: stepsOpacities.length - i
                    }}>
                      <StepItem
                        id={step.id}
                        img={step.img}
                        label={step.label}
                        alignSelf={step.alignSelf}
                        h={step.h}
                        opacity={opacity}
                      />
                    </animated.div>
                  </React.Fragment>
                )
              })
            }
          </Box>
        </VStack>
      </VStack>
    </>
  )
}