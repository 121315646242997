import React from 'react';
import {Icon} from "@chakra-ui/react";
import {componentsSizeTheme} from "../../../config/theme/sizes";


export const LogoIcon = (props) => (
  <Icon
    {...props}
    viewBox="0 0 56 56"
    {...componentsSizeTheme.Logo}
  >
    <path fillRule="evenodd" clipRule="evenodd"
          d="M35.778 16.3594C33.4757 14.8211 30.7689 14 28 14V0C33.5379 0 38.9514 1.64217 43.556 4.71885C48.1605 7.79553 51.7494 12.1685 53.8686 17.2849C55.9879 22.4012 56.5424 28.0311 55.462 33.4625C54.3816 38.894 51.7148 43.8831 47.799 47.799C43.8831 51.7149 38.894 54.3816 33.4625 55.462C28.0311 56.5424 22.4012 55.9879 17.2849 53.8686C12.1685 51.7494 7.79553 48.1605 4.71885 43.556C1.64217 38.9514 0 33.5379 0 28H14C14 30.7689 14.8211 33.4757 16.3594 35.778C17.8978 38.0803 20.0843 39.8747 22.6424 40.9343C25.2006 41.9939 28.0155 42.2712 30.7313 41.731C33.447 41.1908 35.9416 39.8574 37.8995 37.8995C39.8574 35.9416 41.1908 33.447 41.731 30.7313C42.2712 28.0155 41.9939 25.2006 40.9343 22.6424C39.8747 20.0843 38.0803 17.8978 35.778 16.3594Z"
          fill="#547363"/>
  </Icon>
);
