import {lerp, s} from "../helper/common";
import {useBreakpointValue} from "@chakra-ui/react";
import {imgsConfig} from "./components/sections/EcosystemSection/config";


export const opacityHelper = (value, start, end, sectionStart, sectionEnd, type = 'negative') => {
  if (type === 'negative') {
    return Math.max(Math.min(lerp(value, sectionStart, sectionEnd, start, end), start), end)
  }
  return Math.min(lerp(value, sectionStart, sectionEnd, start, end), end)
}


const initialSectionsAnimation = {
  headerOpacity: {
    start: 0.028,
    end: 0.038,
  },
  titleOpacity: {
    start: 0.001,
    end: 0.038,
  },
  devicesImages: {
    start: 0.01,
    end: 0.05,
  },
  descriptionsOpacity: {
    show: {
      start: 0.03,
      end: 0.04,
    },
    hide: {
      start: 0.09,
      end: 0.13,
    }
  },
  devicesBlur: {
    start: 0.09,
    end: 0.13,
  },
  devicesOpacity: {
    start: 0.07,
    end: 0.13,
  },
}


/** Блюр элементов девайсов */
export const ecosystemBlurTo = (value, scrollYProgress) => {
  const start = 0
  const end = 16

  const sectionStart = initialSectionsAnimation.devicesBlur.start
  const sectionEnd = initialSectionsAnimation.devicesBlur.end

  return (
    value < sectionStart
      ? 0
      : Math.min(lerp(value, sectionStart, sectionEnd, start, end), end)
  )
}

/** Прозрачность заблюренных девайсов */
export const ecosystemOpacityTo = (value, scrollYProgress) => {
  const start = 1
  const end = 0.6

  const sectionStart = initialSectionsAnimation.devicesOpacity.start
  const sectionEnd = initialSectionsAnimation.devicesOpacity.end

  return (
    value > sectionEnd * 3
      ? 0.3
      : Math.max(Math.min(lerp(value, sectionStart, sectionEnd, start, end), start), end)
  )
}

/** Скрытие элементов девайсов */
export const descriptionsOpacityTo = (value, scrollYProgress) => {
  const sectionShowStart = initialSectionsAnimation.descriptionsOpacity.show.start
  const sectionShowEnd = initialSectionsAnimation.descriptionsOpacity.show.end

  const sectionHideStart = initialSectionsAnimation.descriptionsOpacity.hide.start
  const sectionHideEnd = initialSectionsAnimation.descriptionsOpacity.hide.end

  const isNeedShow = value <= sectionHideStart

  let start, end, sectionStart, sectionEnd;

  if (isNeedShow) {
    start = 0.1
    end = 1
    sectionStart = sectionShowStart
    sectionEnd = sectionShowEnd
    return Math.min(lerp(value, sectionStart, sectionEnd, start, end), end)
  } else {
    start = 1
    end = 0
    sectionStart = sectionHideStart
    sectionEnd = sectionHideEnd
    return Math.max(Math.min(lerp(value, sectionStart, sectionEnd, start, end), start), end)
  }
}

/** Скрытие тайтла */
export const titleOpacityTo = (value, scrollYProgress) => {
  const start = 1
  const end = 0

  const sectionStart = initialSectionsAnimation.titleOpacity.start
  const sectionEnd = initialSectionsAnimation.titleOpacity.end
  return opacityHelper(value, start, end, sectionStart, sectionEnd)
}

/** Скрытие хедера */
export const headerOpacityTo = (value, scrollYProgress) => {
  const start = 1
  const end = 0
  const sectionStart = initialSectionsAnimation.headerOpacity.start
  const sectionEnd = initialSectionsAnimation.headerOpacity.end
  return opacityHelper(value, start, end, sectionStart, sectionEnd)
}

/**
 *  Анимация изображений
 */
export const useEcosystemAnimations = (props) => {
  const {
    scrollYProgress
  } = props

  const sectionStart = initialSectionsAnimation.devicesImages.start
  const sectionEnd = initialSectionsAnimation.devicesImages.end

  /* snack */
  const snackStartValTop = useBreakpointValue(imgsConfig.snack.start.top)
  const snackEndValTop = useBreakpointValue(imgsConfig.snack.end.top)
  const snackPosTop = scrollYProgress.to((value) => {
    const start = snackStartValTop
    const end = snackEndValTop
    return Math.max(lerp(value, sectionStart, sectionEnd, start, end), end)
  })


  /* lunch */
  const lunchStartValBottom = useBreakpointValue(imgsConfig.lunch.start.bottom)
  const lunchEndValBottom = useBreakpointValue(imgsConfig.lunch.end.bottom)
  const lunchPosBottom = scrollYProgress.to((value) => {
    const start = lunchStartValBottom
    const end = lunchEndValBottom
    return Math.min(lerp(value, sectionStart, sectionEnd, start, end), end)
  })

  /* nutrition */
  const nutritionStartValBottom = useBreakpointValue(imgsConfig.nutrition.start.bottom)
  const nutritionEndValBottom = useBreakpointValue(imgsConfig.nutrition.end.bottom)
  const nutritionPosBottom = scrollYProgress.to((value) => {
    const start = nutritionStartValBottom
    const end = nutritionEndValBottom
    return Math.min(lerp(value, sectionStart, sectionEnd, start, end), end)
  })


  /* snack */
  const tokenStartValTop = useBreakpointValue(imgsConfig.token.start.top)
  const tokenEndValTop = useBreakpointValue(imgsConfig.token.end.top)
  const tokenPosTop = scrollYProgress.to((value) => {
    const start = tokenStartValTop
    const end = tokenEndValTop
    return Math.max(lerp(value, sectionStart, sectionEnd, start, end), end)
  })

  /* Header + title */
  const titleOpacity = scrollYProgress
    .to((value) => titleOpacityTo(value, scrollYProgress))

  const headerOpacity = scrollYProgress
    .to((value) => headerOpacityTo(value, scrollYProgress));

  /* Imgs */
  const ecosystemBlur = scrollYProgress
    .to((value) => ecosystemBlurTo(value, scrollYProgress))

  const ecosystemOpacity = scrollYProgress
    .to((value) => ecosystemOpacityTo(value, scrollYProgress))

  const descriptionsOpacity = scrollYProgress
    .to((value) => descriptionsOpacityTo(value, scrollYProgress))


  return {
    headerOpacity,
    titleOpacity,


    snackPosTop,
    lunchPosBottom,
    nutritionPosBottom,


    tokenPosTop,
    ecosystemBlur,
    ecosystemOpacity,
    descriptionsOpacity,
  }
}

export const useHowToUseAnimations = (props) => {
  const {
    scrollYProgress,
    elementsArr = []
  } = props




  const stepDistance = .03
  const opacitySectionStart = useBreakpointValue(s([.59, .62, .6, .59, .59]))
  const opacitySectionEnd = opacitySectionStart + .01

  const {show, hide} = {
    show: {
      start: 0.1,
      end: 10,
    },
    hide: {
      start: 1,
      end: 0,
      step: stepDistance / 2
    },
  }

  const card1OpacitySectionStart = opacitySectionStart
  const card1OpacitySectionEnd = card1OpacitySectionStart + stepDistance

  const card2OpacitySectionStart = card1OpacitySectionEnd + stepDistance / 2
  const card2OpacitySectionEnd = card2OpacitySectionStart + stepDistance

  const card3OpacitySectionStart = card2OpacitySectionEnd + stepDistance / 2
  const card3OpacitySectionEnd = card3OpacitySectionStart + stepDistance

  const card1Opacity = scrollYProgress.to((value) => {
    if (value > card1OpacitySectionEnd) {
      return opacityHelper(value, hide.start, hide.end, card1OpacitySectionEnd, card1OpacitySectionEnd + hide.step, 'negative')
    }
    return opacityHelper(value, 1, 1, card1OpacitySectionStart, card1OpacitySectionEnd, 'positive')
  })

  const card2Opacity = scrollYProgress.to((value) => {
    if (value > card2OpacitySectionEnd) {
      return opacityHelper(value, hide.start, hide.end, card2OpacitySectionEnd, card2OpacitySectionEnd + hide.step, 'negative')
    }
    return opacityHelper(value, show.start, show.end, card2OpacitySectionStart, card2OpacitySectionEnd, 'positive')
  })

  const card3Opacity = scrollYProgress.to((value) => {
    return opacityHelper(value, show.start, show.end, card3OpacitySectionStart, card3OpacitySectionEnd, 'positive')
  })
  return {
    stepsOpacities: [
      card1Opacity,
      card2Opacity,
      card3Opacity,
    ]
  }
}

export const useRoadmapAnimations = (props) => {
  const {
    scrollYProgress
  } = props

  const stepDistance = .03

  const titleOpacitySectionStart = useBreakpointValue(s([.78, .8, .79, .8, .78]))
  const titleOpacitySectionEnd = titleOpacitySectionStart + .01
  const titleOpacity = scrollYProgress.to((value) => {
    const start = 1
    const end = .2
    return opacityHelper(value, start, end, titleOpacitySectionStart, titleOpacitySectionEnd)
  })

  const {show, hide} = {
    show: {
      start: 0.1,
      end: 10,
    },
    hide: {
      start: 1,
      end: 0,
      step: stepDistance / 2
    },
  }

  // 1
  const card1OpacitySectionStart = titleOpacitySectionEnd
  const card1OpacitySectionEnd = card1OpacitySectionStart + stepDistance
  // 2
  const card2OpacitySectionStart = card1OpacitySectionEnd + stepDistance / 2
  const card2OpacitySectionEnd = card2OpacitySectionStart + stepDistance
  // 3
  const card3OpacitySectionStart = card2OpacitySectionEnd + stepDistance / 2
  const card3OpacitySectionEnd = card3OpacitySectionStart + stepDistance

  const card1Opacity = scrollYProgress.to((value) => {
    if (value > card1OpacitySectionEnd) {
      return opacityHelper(value, hide.start, hide.end, card1OpacitySectionEnd, card1OpacitySectionEnd + hide.step, 'negative')
    }
    return opacityHelper(value, show.start, show.end, card1OpacitySectionStart, card1OpacitySectionEnd, 'positive')
  })


  const card2Opacity = scrollYProgress.to((value) => {
    if (value > card2OpacitySectionEnd) {
      return opacityHelper(value, hide.start, hide.end, card2OpacitySectionEnd, card2OpacitySectionEnd + hide.step, 'negative')
    }
    return opacityHelper(value, show.start, show.end, card2OpacitySectionStart, card2OpacitySectionEnd, 'positive')
  })


  const card3Opacity = scrollYProgress.to((value) => {
    // if (value > card3OpacitySectionEnd) {
    //   return opacityHelper(value, hide.start, hide.end, card3OpacitySectionEnd, card3OpacitySectionEnd + hide.step, 'negative')
    // }
    return opacityHelper(value, show.start, show.end, card3OpacitySectionStart, card3OpacitySectionEnd, 'positive')
  })


  const progressLine1Start = card1OpacitySectionStart
  const progressLine1End = card2OpacitySectionStart
  const progressLine1Width = scrollYProgress.to(value => {
    const start = 1
    const end = 100
    return Math.min(lerp(value, progressLine1Start, progressLine1End, start, end), end)
  })
  const progressLine2Start = card2OpacitySectionEnd
  const progressLine2End = card3OpacitySectionEnd - stepDistance / 4
  const progressLine2Width = scrollYProgress.to(value => {
    const start = 0.1
    const end = 100
    return Math.min(lerp(value, progressLine2Start, progressLine2End, start, end), end)
  })

  const progressIcon1Opacity = scrollYProgress.to(value => Number(value >= progressLine1Start))
  const progressIcon2Opacity = scrollYProgress.to(value => Number(value >= progressLine1End))
  const progressIcon3Opacity = scrollYProgress.to(value => Number(value >= progressLine2End))

  return {
    titleOpacity,

    card1Opacity,
    card2Opacity,
    card3Opacity,

    progressIcon1Opacity,
    progressIcon2Opacity,
    progressIcon3Opacity,

    progressLine1Width,
    progressLine2Width,
  }
}


export const useSimpleParalax = (props) => {
  const {
    scrollYProgress,
    type = 'positive',
    moveStartValue = [0],
    moveEndValue = [],
    sectionStartValue = [],
    sectionEndValue = [],
  } = props


  const sectionStart = useBreakpointValue(s(sectionStartValue))
  const sectionEnd = useBreakpointValue(s(sectionEndValue))

  const start = useBreakpointValue(s(moveStartValue))
  const end = useBreakpointValue(s(moveEndValue))


  return scrollYProgress.to((value) => {
    return opacityHelper(value, start, end, sectionStart, sectionEnd, type)
  })
}


export const useTokenAnimations = (props) => {
  const {
    scrollYProgress,
  } = props
  return useSimpleParalax({
    scrollYProgress,
    moveEndValue: [25, 50, 55, 65, 75],
    sectionStartValue: [0.25, 0.27, 0.26, 0.23],
    sectionEndValue: [0.315, 0.31, 0.295, 0.26],
  })
}



export const meetLunchMoveValue = [25, 55, 80, 80, 110]

export const useMeetTheLunchboxerAnimations = (props) => {
  const {
    scrollYProgress,
  } = props
  return useSimpleParalax({
    scrollYProgress,
    moveEndValue: [0],
    type: 'negative',
    moveStartValue: meetLunchMoveValue,
    sectionStartValue: [0.48, 0.52, 0.48, 0.48, 0.5],
    sectionEndValue: [0.53, 0.57, 0.53, 0.53, 0.545],
  })
}