import React from 'react';
import {spacings} from "../../../../config/theme/sizes";
import {VStack} from "@chakra-ui/react";
import {s} from "../../../../helper/common";
import {DualWithDotBlock} from "../../../../components/DualWithDotBlock";
import {GradientText, TelegramIcon} from "../../../../components";
import {themeColors} from "../../../../config/theme/colors";
import {SliderBlock} from "./components/SliderBlock";
import {navigationKeys} from "../../../../config/navigation";
import {networksLinks} from "../../../../config/links";

export const NewsSection = (props) => {
  return (
    <VStack
      id={navigationKeys.news.key}
      gap={s(["40px", "64px", "64px", "80px", "96px"])}
      p={spacings.section.main}
    >
      <DualWithDotBlock
        dotColor={'green.700'}
        dotText={"News and Latest Project Updates"}
        mainText={'stay in touch with the project’s progress'}
        btnBgColor={themeColors.grey["50"]}
        btnHref={networksLinks.telegram}
        mainTextW={s(['100%', "506px", "558px", "792px", "792px"])}
        dualProps={{
          templateRows: s(['52px 1fr', '52px 1fr', '1fr']),
          templateColumns: s(['1fr', '1fr', '434px 1fr', '616px 1fr', '780px 1fr'])
        }}
        btnText={
          <GradientText text={"@cryptofood"}/>
        }
        btnIcon={
          <TelegramIcon
            color={'green.300'}
            w={s(["12px", "24px"])}
            h={s(["12px", "24px"])}
          />
        }
      />

      <SliderBlock/>
    </VStack>
  );
};
