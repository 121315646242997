import {Box} from "@chakra-ui/react";
import {s} from "../helper/common";
import React from "react";


export const ColorCircle = (props) => {
  const size = s(["245px", "507px", "773px", "1023px", "1023px"])
  return (
    <Box
      width={size}
      height={size}
      {...props}
    />
  )
}