import {Icon} from "@chakra-ui/react";

export const CoinIcon = (props) => (
  <Icon
    color={"currentColor"}
    {...props}
    viewBox="0 0 64 64"
  >
    <path d="M38.6602 26.9035C38.5908 25.4621 37.7552 24.034 36.2433 22.5384L36.8685 21.9065C38.4454 23.4665 39.463 25.0939 39.5481 26.8608C39.6337 28.6397 38.7696 30.4355 36.8685 32.3162C35.0321 34.1328 34.2205 35.5496 34.2205 36.8891C34.2205 38.2286 35.0322 39.6454 36.8685 41.462L36.2433 42.094C34.3732 40.2439 33.3316 38.6052 33.3316 36.8891C33.3316 35.1731 34.3732 33.5343 36.2433 31.6843C38.0487 29.8983 38.729 28.333 38.6602 26.9035Z" fill="currentColor"/>
    <path d="M28.2433 22.5384C29.7552 24.034 30.5908 25.4621 30.6602 26.9035C30.729 28.333 30.0487 29.8983 28.2433 31.6843C26.3732 33.5343 25.3316 35.1731 25.3316 36.8891C25.3316 38.6052 26.3732 40.2439 28.2433 42.094L28.8685 41.462C27.0322 39.6454 26.2205 38.2286 26.2205 36.8891C26.2205 35.5496 27.0321 34.1328 28.8685 32.3162C30.7696 30.4355 31.6337 28.6397 31.5481 26.8608C31.463 25.0939 30.4454 23.4665 28.8685 21.9065L28.2433 22.5384Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M31.9983 10.2224C19.9707 10.2224 10.2205 19.9727 10.2205 32.0002C10.2205 44.0278 19.9707 53.778 31.9983 53.778C44.0258 53.778 53.776 44.0278 53.776 32.0002C53.776 19.9727 44.0258 10.2224 31.9983 10.2224ZM11.1094 32.0002C11.1094 20.4636 20.4616 11.1113 31.9983 11.1113C43.5349 11.1113 52.8872 20.4636 52.8872 32.0002C52.8872 43.5368 43.5349 52.8891 31.9983 52.8891C20.4616 52.8891 11.1094 43.5368 11.1094 32.0002Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M31.9983 3.11133C16.0434 3.11133 3.10938 16.0453 3.10938 32.0002C3.10938 47.9551 16.0434 60.8891 31.9983 60.8891C47.9532 60.8891 60.8872 47.9551 60.8872 32.0002C60.8872 16.0453 47.9532 3.11133 31.9983 3.11133ZM3.99826 32.0002C3.99826 16.5362 16.5343 4.00022 31.9983 4.00022C47.4622 4.00022 59.9983 16.5362 59.9983 32.0002C59.9983 47.4642 47.4622 60.0002 31.9983 60.0002C16.5343 60.0002 3.99826 47.4642 3.99826 32.0002Z" fill="currentColor"/>
  </Icon>
)