import {Box, HStack, Link, VStack} from "@chakra-ui/react";
import React from "react";
import {s} from "../../../../../helper/common";
import {Body1, DextoolsIcon, EtherscanIcon, Label1} from "../../../../../components";


export const DescriptionBlock = (props) => {
  return (
    <VStack
      w={s(["100%", "552px", "496px", '530px', "702px"])}
      gap={s(['16px', "24px", "32px", "40px", '48px'])}
    >
      <Body1 color={'grey.50'}>
        Our tokenomics model allows you to become part of a community built around the idea of functional nutrition.
        By holding and participating in our token, investors and partners can tap into the growing potential of the
        Cryptofood® ecosystem and be involved in loyalty program. The token will also be used to reward active
        customers and reliable partners.
      </Body1>

      <VStack
        gap={s(["12px", "16px", "16px", "24px", "32px"])}
        align="left"
        w={'100%'}
      >
        <Label1 color={'whiteAlpha.600'}>
          Will be available at
        </Label1>
        <HStack
          gap={s(["16px", "24px", "24px", "32px", "32px"])}
        >
          <Link
            href={'https://www.dextools.io/app/ru/ether/pair-explorer/0xd66b2287fa5693f448256179a1e8f9db7be8f4f2?t=1710496238582'}
            target="_blank"
            style={{"-webkit-tap-highlight-color": "rgba(255, 255, 255, 0)"}}
          >
            <DextoolsIcon
              w={s(["20px", "34px", "34px", "41px", '49px'])}
              h={s(["24px", "40px", "40px", "47px", '56px'])}
            />
          </Link>
          <Link
            href={'https://etherscan.io/token/0xeb0c4ad0efa1e8d76953a3e69a6ad5a494dfcf84'}
            target="_blank"
            style={{"-webkit-tap-highlight-color": "rgba(255, 255, 255, 0)"}}
          >
            <EtherscanIcon
              w={s(["105px", "175px", "175px", "210px", '256px'])}
              h={s(["24p", "40px", "40px", "48px", '56px'])}
            />
          </Link>
        </HStack>
      </VStack>
    </VStack>
  )
}