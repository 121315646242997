import React from "react";
import {Icon} from "@chakra-ui/react";

export const HamburgerIcon = (props) => (
  <Icon
    color={"currentColor"}
    {...props}
    viewBox="0 0 48 48"
  >
    <path d="M8 15H40V17H8V15Z"  fill="currentColor"/>
    <path d="M8 23H40V25H8V23Z"  fill="currentColor"/>
    <path d="M40 31H8V33H40V31Z"  fill="currentColor"/>
  </Icon>
)