import React from "react";
import {Body1, Label1} from "../../../../../components";
import {themeColors} from "../../../../../config/theme/colors";
import {Box, HStack, Stack, useClipboard, useToast} from "@chakra-ui/react";
import {CopyIcon} from "@chakra-ui/icons";

export const TokenContract = () => {
  const toast = useToast()

  const tokenValue = '0xEb0c4ad0EFa1e8D76953a3E69a6ad5A494DFCf84'
  const tokenDisplay = '0xEb...Cf84'
  const {onCopy} = useClipboard(tokenValue);
  const onClickCopy = () => {
    onCopy()
    toast({
      title: 'Contract copied.',
      status: 'success',
      duration: 1000,
    })
  }

  return (
    <Stack
      direction={['row']}
      gap={'20px'}
      color={"whiteAlpha.600"}
      alignItems={["center"]}
    >
      <Label1 color={"currentColor"}>
        Contract ERC20
      </Label1>
      <Body1
        letterSpacing={'2px'}
        color={"currentColor"}
        p={'4px 2px'}
        border={`1px solid ${themeColors.whiteAlpha["600"]}`}
        borderRadius={'8px'}
        cursor={'pointer'}
        onClick={onClickCopy}
      >
        {tokenDisplay}
      </Body1>
      <Box
        as={'button'}
        onClick={onClickCopy}
      >
        <CopyIcon/>
      </Box>
    </Stack>
  )
}