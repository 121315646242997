import { descriptionsConfig } from "./config";
import { DescriptionBlock } from "../../../../components";
import { animated } from '@react-spring/web'


export const NutritionDescription = ({ descriptionsOpacity, ...rest }) =>
  <animated.div style={{ opacity: descriptionsOpacity }}>
    <DescriptionBlock
      {...descriptionsConfig.nutrition}
      {...rest}
      extraMainLabel={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    />
  </animated.div>

export const LunchBoxDescription = ({ descriptionsOpacity, ...rest }) => (
  <animated.div style={{opacity: descriptionsOpacity}}>
    <DescriptionBlock
      {...descriptionsConfig.lunch}
      {...rest}
    />
  </animated.div>
)

export const TokenDescription = ({ descriptionsOpacity, ...rest }) =>
  <animated.div style={{ opacity: descriptionsOpacity }}>
    <DescriptionBlock
      {...descriptionsConfig.token}
      {...rest}
    />
  </animated.div>

export const SnackDescription = ({ descriptionsOpacity, ...rest }) =>
  <animated.div style={{ opacity: descriptionsOpacity }}>
    <DescriptionBlock
      {...descriptionsConfig.snack}
      {...rest}
    />
  </animated.div>

