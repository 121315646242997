import {s} from "../../../../helper/common";


export const imgsConfig = {
  snack: {
    start: {
      top: s([8]),
      left: s([15, 17, 15, 18, 15]),
    },
    end: {
      top: s([-1,-1,-1, -7]),
    }
  },
  lunch: {
    start: {
      bottom: s([1]),
      left: s([5, 6, 4.5, 8, 5]),
    },
    end: {
      bottom: s([30, 20])
    }
  },
  nutrition: {
    start: {
      bottom: [1],
      right: s([5, 5, 5, 2, 5])
    },
    end: {
      bottom: s([20, 20])
    }
  },
  token: {
    start: {
      top: s([4, 8, 4]),
      right: s([15, 17, 15, 13, 16])
    },
    end: {
      top: s([5, -5, -5, -12]),
    }
  }
}

export const descriptionsConfig = {
  lunch: {
    title: "LunchBoxer",
    description: "Portable food warming device, designed to replace a full meal - lunch or dinner",
    textW: s(['138px', '212px', '262px', '352px', '396px']),
    lineW: s(['72px', '198px', '248px', '394px', '532px']),
    direction: s(['row']),
    textAlign: s(['left']),
    containerAlignItems: s(['start'])
  },
  snack: {
    title: "SnackSprinter",
    description: "Pocket-sized device for quick on-the-go snacks, heating food in small portions",
    textW: s(['138px', '280px', '352px', '240px', '396px']),
    lineW: s(['64px', '54px', '122px', '206px', '298px']),
    direction: s(['row-reverse', 'row']),
    textAlign: s(['right', 'left']),
    containerAlignItems: s(['end', 'start'])
  },
  nutrition: {
    title: "Functional Nutrition",
    description: "A range of innovative food options specifically designed to be compatible with our heating devices.",
    textW: s(['138px', '150px', '243px', '302px', '350px']),
    lineW: s(['64px', '136px', '229px', '318px', '495px']),
    direction: s(['row-reverse']),
    textAlign: s(['right']),
    containerAlignItems: s(['end'])
  },
  token: {
    title: "CFD Token",
    description: "Join us and invest in this groundbreaking  venture that is set to redefine the future of food",
    textW: s(['138px', '240px', '352px', '240px', '396px']),
    lineW: s(['52px', '68px', '150px', '162px', '298px']),
    direction: s(['row', 'row-reverse']),
    textAlign: s(['left', 'right']),
    containerAlignItems: s(['start', 'end'])
  },
}